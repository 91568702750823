import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';

import { threeMonthsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxPxWorkflowHistorySubListDefaults, fluxDocumentSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CustomerCashSwapList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerCashSwapList',
        entityName: 'PartnerCashSwap',
		fetchUriTemplate: 'PartnerCashSwap/GetCustomerWrkflwCashSwaps(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'SwapCurrency', 'SwapRateRequested', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: ['Partner($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Description', 'VoucherNo', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	CustomerCashSwapDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'CustomerCashSwapDocumentSubList',
		fetchUriTemplate: 'PartnerCashSwap(${subListParentId})/Documents',
		exportUriTemplate: 'PartnerCashSwap(${subListParentId})/Documents(${entityId})/Export',
	},

	CustomerCashSwapWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'CustomerCashSwapWorkflowHistorySubList',
		fetchUriTemplate: 'PartnerCashSwap(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'PartnerCashSwap(${subListParentId})/WorkflowHistory/Export',
	},

	CustomerPaymentList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerPaymentList',
		entityName: 'PartnerPayment',
		//fetchUriTemplate: 'PartnerPayment',
		fetchUriTemplate: 'PartnerPayment/GetCustomerWrkflwPayments(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'PartnerPayment/ExportCustomerWrkflwPayments',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'TransactionReference', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: ['Partner($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Description', 'VoucherNo', 'TransactionReference', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	CustomerPaymentPick: {
		...fluxPickDefaults,
		actionNamespace: 'CustomerPaymentPick',
		entityName: 'PartnerPayment',
		fetchUriTemplate: 'PartnerPayment',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'TransactionReference', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: ['Partner($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Description', 'VoucherNo', 'TransactionReference', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
		},
	},

	CustomerPaymentDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'CustomerPaymentDocumentSubList',
		fetchUriTemplate: 'PartnerPayment(${subListParentId})/Documents',
		exportUriTemplate: 'PartnerPayment(${subListParentId})/Documents(${entityId})/Export',
	},

	CustomerPaymentWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'CustomerPaymentWorkflowHistorySubList',
		fetchUriTemplate: 'PartnerPayment(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'PartnerPayment(${subListParentId})/WorkflowHistory/Export',
	},

	CustomerReceiptList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerReceiptList',
		entityName: 'PartnerReceipt',
		//fetchUriTemplate: 'PartnerReceipt',
		fetchUriTemplate: 'PartnerReceipt/GetCustomerWrkflwReceipts(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'PartnerReceipt/ExportCustomerWrkflwReceipts',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'TransactionReference', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: ['Partner($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Description', 'VoucherNo', 'TransactionReference', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	CustomerReceiptPick: {
		...fluxPickDefaults,
		actionNamespace: 'CustomerReceiptPick',
		entityName: 'PartnerReceipt',
		fetchUriTemplate: 'PartnerReceipt',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'TransactionReference', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: ['Partner($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Description', 'VoucherNo', 'TransactionReference', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
		},
	},

	CustomerReceiptDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'CustomerReceiptDocumentSubList',
		fetchUriTemplate: 'PartnerReceipt(${subListParentId})/Documents',
		exportUriTemplate: 'PartnerReceipt(${subListParentId})/Documents(${entityId})/Export',
	},

	CustomerReceiptWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'CustomerReceiptWorkflowHistorySubList',
		fetchUriTemplate: 'PartnerReceipt(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'PartnerReceipt(${subListParentId})/WorkflowHistory/Export',
	},

	CustomerTransferList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerTransferList',
		entityName: 'PartnerTransfer',
		fetchUriTemplate: 'PartnerTransfer/GetCustomerWrkflwTransfers(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: ['Transferor($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Description', 'VoucherNo', 'Transferor/PartnerNo', 'Transferor/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	CustomerTransferDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'CustomerTransferDocumentSubList',
		fetchUriTemplate: 'PartnerTransfer(${subListParentId})/Documents',
		exportUriTemplate: 'PartnerTransfer(${subListParentId})/Documents(${entityId})/Export',
	},

	CustomerTransferWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'CustomerTransferWorkflowHistorySubList',
		fetchUriTemplate: 'PartnerTransfer(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'PartnerTransfer(${subListParentId})/WorkflowHistory/Export',
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	CustomerCashSwapView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerCashSwapView',
		entityName: 'PartnerCashSwap',
		fetchUriTemplate: 'PartnerCashSwap',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'SwapCompany($select=CoShortCode,CoName,CoOrgType)',
			'CollectAcct($select=AcctNo,AcctTitle,OrgCompanyId)',
			'PayoutAcct($select=AcctNo,AcctTitle,OrgCompanyId)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	CustomerPaymentView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerPaymentView',
		entityName: 'PartnerPayment',
		fetchUriTemplate: 'PartnerPayment',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'ContraCompany($select=CoShortCode,CoName,CoOrgType)',
			'ContraAcct($select=OrgCompanyId,AcctNo,AcctTitle)',
			'FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	CustomerReceiptView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerReceiptView',
		entityName: 'PartnerReceipt',
		fetchUriTemplate: 'PartnerReceipt',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'ContraCompany($select=CoShortCode,CoName,CoOrgType)',
			'ContraAcct($select=OrgCompanyId,AcctNo,AcctTitle)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	CustomerTransferView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerTransferView',
		entityName: 'PartnerTransfer',
		fetchUriTemplate: 'PartnerTransfer',
		selectFields: [],
		expandProps: [
			'Transferor($select=PartnerNo,PartnerLabel)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'PartnerTransferEntries($expand=Transferee($select=PartnerNo,PartnerLabel))'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
