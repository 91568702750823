import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, tenYearsAgo, threeMonthsAgo, threeYearsAgo, today } from 'app-model/ModelConstants';
import { ContractClosure } from 'app-model/enums';
import { CurrencyCodes, PartnerState, TermInstrumentClosure } from 'app-model/enums';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CustomerDocumentTOList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerDocumentTOList',
		entityName: 'CustomerDocumentTO',
		fetchUriTemplate: 'orgcompany(${companyId})/GetCustomerKypDocuments(SubClassId=${subClassId},DocumentClass=${enumParamDocumentClass},StaffId=${staffId},SearchText=${searchText})',
		exportUriTemplate: 'orgcompany(${companyId})/ExportCustomerKypDocuments',
		orderBy: 'PartnerLabel asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['PartnerNo', 'PartnerLabel'],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			enumParamDocumentClass: EnumAllOptionValue,
			staffId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},
	
	CustomerOpeningList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerOpeningList',
		entityName: 'CustomerOpening',
		fetchUriTemplate: 'CustomerOpening',
		orderBy: 'RequestDate desc, Subclasscode asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
			partnerClass: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},

	CustomerSubClassList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerSubClassList',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyPartnerSubClass()',
		orderBy: 'OrgCompany/CoShortCode asc, ClassCode asc, Subclasscode asc',
		selectFields: ['Id', 'ClassCode', 'SubClassCode', 'IsDefault', 'IsManagedGroup', 'IsExternallyManaged', 'DefaultCommissionSlice', 'HonorThirdPartyPayments', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
			companyId: 1,
			partnerClass: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	CustomerSubClassPick: {
		...fluxPickDefaults,
		actionNamespace: 'CustomerSubClassPick',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyPartnerSubClass()',
		orderBy: 'OrgCompany/CoShortCode asc, ClassCode asc, Subclasscode asc',
		selectFields: ['Id', 'ClassCode', 'SubClassCode', 'IsDefault', 'IsManagedGroup', 'IsExternallyManaged', 'DefaultCommissionSlice', 'HonorThirdPartyPayments', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
		},
	},

	EQOrderSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQOrderSubList',
		entityName: 'EQOrder',
		fetchUriTemplate: 'Customer(${subListParentId})/GetEQOrderForCustomer(StartDate=${startDate},EndDate=${endDate},Channel=${enumParamChannel},OrderType=${enumParamOrderType},InstrId=${instrId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportEQOrderForCustomer',
		orderBy: 'OrderDate desc, EQAccount/AccountTitle asc',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName,Currency;$expand=ListExchange($select=UniqueCode,CommonName))',
		],
		searchFields: ['EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrId: NullEntityId,
			enumParamChannel: EnumAllOptionValue,
			enumParamOrderType: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	TermInvestSubList: {
		...fluxListDefaults,
		actionNamespace: 'TermInvestSubList',
		entityName: 'TermInvest',
		fetchUriTemplate: 'Customer(${subListParentId})/GetTermInvestForCustomer(StartDate=${startDate},EndDate=${endDate},TermTypeId=${termTypeId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportTermInvestForCustomer',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'InvestType($select=TypeCode,TypeDesc,InterestRateDays)'
		],
		searchFields: ['Partner/PartnerNo', 'Partner/PartnerLabel', 'InvestType/TypeCode', 'InvestType/TypeDesc'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			termTypeId: NullEntityId,
			enumParamClosureType: TermInstrumentClosure.Running,
		},
	},

	TermLoanSubList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanSubList',
		entityName: 'TermLoan',
		fetchUriTemplate: 'Customer(${subListParentId})/GetTermLoanForCustomer(StartDate=${startDate},EndDate=${endDate},TermTypeId=${termTypeId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportTermLoanForCustomer',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'LoanType($select=TypeCode,TypeDesc,InterestRateDays)'
		],
		searchFields: ['Partner/PartnerNo', 'Partner/PartnerLabel', 'LoanType/TypeCode', 'LoanType/TypeDesc'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			termTypeId: NullEntityId,
			enumParamClosureType: TermInstrumentClosure.Running,
		},
	},

	TRTBillOrderSubList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillOrderSubList',
		entityName: 'TRTBillOrder',
		fetchUriTemplate: 'Customer(${subListParentId})/GetTRTBillOrderForCustomer(StartDate=${startDate},EndDate=${endDate},Channel=${enumParamChannel},OrderType=${enumParamOrderType},Currency=${enumParamCurrency},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportTRTBillOrderForCustomer',
		orderBy: 'OrderDate desc, Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
			'TRTBillType($select=TypeCode,Country,Currency)',
		],
		searchFields: ['Customer/PartnerNo', 'Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			enumParamChannel: EnumAllOptionValue,
			enumParamOrderType: EnumAllOptionValue,
			enumParamCurrency: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	TRTBillTranSubList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillTranSubList',
		entityName: 'TRTBillTran',
		fetchUriTemplate: 'Customer(${subListParentId})/GetTRTBillTranForCustomer(StartDate=${startDate},EndDate=${endDate},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportTRTBillTranForCustomer',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'TRTBillType($select=TypeCode,Country,Currency)',
			'TRTBillDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
		searchFields: ['AllotNotes', 'BillTranNo', 'Warehouse/PartnerNo', 'Warehouse/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamClosureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	CustomerValuation: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerValuation',
		entityName: 'Customer',
		fetchUriTemplate: 'Customer',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName)',
			'PartnerSubClass($select=SubClassCode)',
			'BenchmarkInstrument($select=InstrumentCode,InstrumentName)',
			'MasterRecord($expand=PrimaryAddress,OrgLocation($select=LocationCode,Description))',
			'PartnerGlAccts($expand=OrgCompany($select=CoShortCode,CoName),FAGlAcct($select=AcctNo,AcctTitle,CashAcctNo))',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
