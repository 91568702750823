import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { NullEntityId, threeMonthsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';

const stLists : IListComponentControlList = {
	ScItemSaleClassList: {
		...fluxListDefaults,
		actionNamespace: 'ScItemSaleClassList',
        entityName: 'ScItemSaleClass',
		fetchUriTemplate: 'ScItemSaleClass',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	ScMeasureUnitClassList: {
		...fluxListDefaults,
		actionNamespace: 'ScMeasureUnitClassList',
        entityName: 'ScMeasureUnitClass',
		fetchUriTemplate: 'ScMeasureUnitClass',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	ScMeasureUnitList: {
		...fluxListDefaults,
		actionNamespace: 'ScMeasureUnitList',
        entityName: 'ScMeasureUnit',
		fetchUriTemplate: 'ScMeasureUnit',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			unitClassId: NullEntityId,
		},
	},
	ScMeasureUnitPick: {
		...fluxPickDefaults,
		actionNamespace: 'ScMeasureUnitPick',
        entityName: 'ScMeasureUnit',
		fetchUriTemplate: 'ScMeasureUnit',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	ScItemSaleClassView: {
		...fluxViewDefaults,
		actionNamespace: 'ScItemSaleClassView',
		entityName: 'ScItemSaleClass',
		fetchUriTemplate: 'ScItemSaleClass',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
