import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, threeMonthsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';

const stLists : IListComponentControlList = {
	FACashDisburseList: {
		...fluxListDefaults,
		actionNamespace: 'FACashDisburseList',
		entityName: 'FACashDisburse',
		fetchUriTemplate: 'orgcompany(${companyId})/CashDisburse',
		exportUriTemplate: 'orgcompany(${companyId})/CashDisburse/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			assignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FACashDisburseDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FACashDisburseDocumentSubList',
		fetchUriTemplate: 'FACashDisburse(${subListParentId})/Documents',
		exportUriTemplate: 'FACashDisburse(${subListParentId})/Documents(${entityId})/Export',
	},

	FACashDisburseWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FACashDisburseWorkflowHistorySubList',
		fetchUriTemplate: 'FACashDisburse(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'FACashDisburse(${subListParentId})/WorkflowHistory/Export',
	},

	FAExpenseAdvanceList: {
		...fluxListDefaults,
		actionNamespace: 'FAExpenseAdvanceList',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance',
		exportUriTemplate: 'FAExpenseAdvance/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			assignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	FAExpenseAdvancePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAExpenseAdvancePick',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	FAExpenseAdvanceDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FAExpenseAdvanceDocumentSubList',
		fetchUriTemplate: 'FAExpenseAdvance(${subListParentId})/Documents',
		exportUriTemplate: 'FAExpenseAdvance(${subListParentId})/Documents(${entityId})/Export',
	},

	FAExpenseAdvanceWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FAExpenseAdvanceWorkflowHistorySubList',
		fetchUriTemplate: 'FAExpenseAdvance(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'FAExpenseAdvance(${subListParentId})/WorkflowHistory/Export',
	},

	FAExpenseRetireList: {
		...fluxListDefaults,
		actionNamespace: 'FAExpenseRetireList',
		entityName: 'FAExpenseRetire',
		fetchUriTemplate: 'FAExpenseRetire',
		exportUriTemplate: 'FAExpenseRetire/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			assignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FAExpenseRetireDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FAExpenseRetireDocumentSubList',
		fetchUriTemplate: 'FAExpenseRetire(${subListParentId})/Documents',
		exportUriTemplate: 'FAExpenseRetire(${subListParentId})/Documents(${entityId})/Export',
	},

	FAExpenseRetireWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FAExpenseRetireWorkflowHistorySubList',
		fetchUriTemplate: 'FAExpenseRetire(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'FAExpenseRetire(${subListParentId})/WorkflowHistory/Export',
	},

	FAExpenseTypeList: {
		...fluxListDefaults,
		actionNamespace: 'FAExpenseTypeList',
		entityName: 'FAExpenseType',
		fetchUriTemplate: 'orgcompany(${companyId})/ExpenseTypes',
		exportUriTemplate: 'orgcompany(${companyId})/ExpenseTypes/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			active: EnumAllOptionValue,
		},
	},
	FAExpenseTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAExpenseTypePick',
		entityName: 'FAExpenseType',
		fetchUriTemplate: 'orgcompany(${companyId})/ExpenseTypes',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	FAIncomeDirectList: {
		...fluxListDefaults,
		actionNamespace: 'FAIncomeDirectList',
		entityName: 'FAIncomeDirect',
		fetchUriTemplate: 'orgcompany(${companyId})/IncomeDirect',
		exportUriTemplate: 'orgcompany(${companyId})/IncomeDirect/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FAIncomeDirectDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FAIncomeDirectDocumentSubList',
		fetchUriTemplate: 'FAIncomeDirect(${subListParentId})/Documents',
		exportUriTemplate: 'FAIncomeDirect(${subListParentId})/Documents(${entityId})/Export',
	},

	FAIncomeTypeList: {
		...fluxListDefaults,
		actionNamespace: 'FAIncomeTypeList',
		entityName: 'FAIncomeType',
		fetchUriTemplate: 'orgcompany(${companyId})/IncomeTypes',
		exportUriTemplate: 'orgcompany(${companyId})/IncomeTypes/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			active: EnumAllOptionValue,
		},
	},
	FAIncomeTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAIncomeTypePick',
		entityName: 'FAIncomeType',
		fetchUriTemplate: 'orgcompany(${companyId})/IncomeTypes',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FACashDisburseView: {
		...fluxViewDefaults,
		actionNamespace: 'FACashDisburseView',
		entityName: 'FACashDisburse',
		fetchUriTemplate: 'FACashDisburse',
		queryUseRestEndpoint: true,
	},

	FAExpenseAdvanceView: {
		...fluxViewDefaults,
		actionNamespace: 'FAExpenseAdvanceView',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance',
		queryUseRestEndpoint: true,
	},

	FAExpenseRetireView: {
		...fluxViewDefaults,
		actionNamespace: 'FAExpenseRetireView',
		entityName: 'FAExpenseRetire',
		fetchUriTemplate: 'FAExpenseRetire',
		queryUseRestEndpoint: true,
	},

	FAExpenseTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'FAExpenseTypeView',
		entityName: 'FAExpenseType',
		fetchUriTemplate: 'FAExpenseType',
		queryUseRestEndpoint: true,
	},

	FAIncomeDirectView: {
		...fluxViewDefaults,
		actionNamespace: 'FAIncomeDirectView',
		entityName: 'FAIncomeDirect',
		fetchUriTemplate: 'FAIncomeDirect',
		queryUseRestEndpoint: true,
	},

	FAIncomeTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'FAIncomeTypeView',
		entityName: 'FAIncomeType',
		fetchUriTemplate: 'FAIncomeType',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
