
import { SysActionCore } from './_SysActionCore';

export const SysActions = {
	...SysActionCore,

	FILTER: "FILTER",

	LIST_CHANGEFILTER: "LIST_CHANGEFILTER",
	LIST_CHANGEPAGE: "LIST_CHANGEPAGE",
	LIST_CHANGESORT: "LIST_CHANGESORT",
	LIST_CMDSTART: "LIST_CMDSTART",
	LIST_CMDEND: "LIST_CMDEND",
	LIST_FETCHDATA: "LIST_FETCHDATA",
	LIST_RECEIVEDATA: "LIST_RECEIVEDATA",
	LIST_RESET: "LIST_RESET",
	LIST_SELECTITEMS: "LIST_SELECTITEMS",

	REFRESH: "REFRESH",

	SAVE: "SAVE",
	SAVEALL: "SAVEALL",
	SAVEANDCLOSE: "SAVEANDCLOSE",
	SAVEANDNEW: "SAVEANDNEW",

	SEARCH: "SEARCH",

	VIEW_CMDSTART: "VIEW_CMDSTART",
	VIEW_CMDEND: "VIEW_CMDEND",	
	VIEW_FETCHDATA: "VIEW_FETCHDATA",
	VIEW_RECEIVEDATA: "VIEW_RECEIVEDATA",
};

export type SysActions = typeof SysActions[keyof typeof SysActions];
