export const SysActionCore = {
	ADDITEM: "ADDITEM",

	ADDNOTES: "ADDNOTES",

	ADVANCE: "ADVANCE",

	APPLYTEMPLATE: "APPLYTEMPLATE",

	ARCHIVEDOWNLOAD: "ARCHIVEDOWNLOAD",

	AUDITINTERNAL: "AUDITINTERNAL",

	AUDITEXTERNAL: "AUDITEXTERNAL",

	AUTOMATCH: "AUTOMATCH",

	CANCELORDERS: "CANCELORDERS",

	CASECREATE: "CASECREATE",

	CERTRECEIVE: "CERTRECEIVE",

	CHANGEACCT: "CHANGEACCT",

	CHANGEASSIGNEE: "CHANGEASSIGNEE",

	CHANGEPARENT: "CHANGEPARENT",

	CHANGEPAY: "CHANGEPAY",

	CHANGEPOSITION: "CHANGEPOSITION",

	CHANGERATE: "CHANGERATE",

	CHANGETEMPLATE: "CHANGETEMPLATE",

	CHANGEUSERNAMEEMAIL: "CHANGEUSERNAMEEMAIL",

	CONVERT: "CONVERT",

	COSTAUTOSETUP: "COSTAUTOSETUP",

	COSTAUTOSETUPALL: "COSTAUTOSETUPALL",

	COSTREBUILD: "COSTREBUILD",

	COSTREBUILDALL: "COSTREBUILDALL",

	CREATECASE: "CREATECASE",

	CREATEMISSING: "CREATEMISSING",

	DEALALLOTAUTO: "DEALALLOTAUTO",

	DEALALLOTJOB: "DEALALLOTJOB",

	DEALALLOTUNDO: "DEALALLOTUNDO",

	DEALALLOTAPPROVE: "DEALALLOTAPPROVE",

	DEALALLOTUNAPPROVE: "DEALALLOTUNAPPROVE",

	DEALALLOTSEND: "DEALALLOTSEND",

	DEALALLOTRESEND: "DEALALLOTRESEND",

	DELETEITEM: "DELETEITEM",

	DEMOBILIZE: "DEMOBILIZE",

	DEPRECIATE: "DEPRECIATE",

	DEPRECIATEALL: "DEPRECIATEALL",

	DISBURSE: "DISBURSE",

	DISPATCH: "DISPATCH",

	DISPOSE: "DISPOSE",

	DOCUMENTADD: "DOCUMENTADD",

	DOCUMENTVOID: "DOCUMENTVOID",

	DOCUMENTCHECK: "DOCUMENTCHECK",

	DOCUMENTVERIFY: "DOCUMENTVERIFY",

	ERECEIVE: "ERECEIVE",

	FAREPOST: "FAREPOST",

	GETGEODATA: "GETGEODATA",

	GETME: "GETME",

	HANDOVER: "HANDOVER",

	HRCOMMUNICATE: "HRCOMMUNICATE",

	HRUPDATE: "HRUPDATE",

	HRVIEW: "HRVIEW",

	INTERESTRUN: "INTERESTRUN",

	INTERESTRUNALL: "INTERESTRUNALL",

	INTERESTREWIND: "INTERESTREWIND",

	INTERESTREWINDALL: "INTERESTREWINDALL",

	LINK: "LINK",

	LOADDEALS: "LOADDEALS",

	LOGCALL: "LOGCALL",

	LOGOUTFIX: "LOGOUTFIX",

	MANUALDEALALLOT: "MANUALDEALALLOT",

	MANUALFULLMATCH: "MANUALFULLMATCH",

	MANUALPARTIALMATCH: "MANUALPARTIALMATCH",

	MANUALREVERSALMATCH: "MANUALREVERSALMATCH",

	MARKETDATA: "MARKETDATA",

	MGTREVIEW: "MGTREVIEW",

	MIGRATECOMPANY: "MIGRATECOMPANY",

	MOVESTORE: "MOVESTORE",

	NEXTNUM: "NEXTNUM",

	PICKUP: "PICKUP",

	PROCESSORDERS: "PROCESSORDERS",

	PURGEDEALS: "PURGEDEALS",

	RECOGNIZE: "RECOGNIZE",

	REFINANCE: "REFINANCE",

	REGDISPATCH: "REGDISPATCH",

	RESEND: "RESEND",

	RESPOND: "RESPOND",

	RETIRE: "RETIRE",

	RETRIEVE: "RETRIEVE",

	RETURNOWNER: "RETURNOWNER",

	REVERSESTATUS: "REVERSESTATUS",

	ROLLOVER: "ROLLOVER",

	RUNSCHEDULE: "RUNSCHEDULE",

	SECURITYSTATUS: "SECURITYSTATUS",

	SENDOTP: "SENDOTP",

	SETUPFEED: "SETUPFEED",

	SCORE: "SCORE",

	SEVEREMATCH: "SEVEREMATCH",

	SETTLE: "SETTLE",

	SKIP: "SKIP",

	SOURCEMOVE: "SOURCEMOVE",

	STARTFIX: "STARTFIX",

	STARTTRADE: "STARTTRADE",

	STATUSORDERS: "STATUSORDERS",

	STOPFIX: "STOPFIX",

	STMTAUTOPOST: "STMTAUTOPOST",

	STMTEREVERSE: "STMTEREVERSE",

	TERMINATE: "TERMINATE",

	TRADECAPTURE: "TRADECAPTURE",

	UNADVANCE: "UNADVANCE",

	UNDEPRECIATE: "UNDEPRECIATE",

	UNDEPRECIATEALL: "UNDEPRECIATEALL",

	UNRETIRE: "UNRETIRE",

	UNSETTLE: "UNSETTLE",

	VALUATIONDO: "VALUATIONDO",

	VALUATIONDOALL: "VALUATIONDOALL",

	VALUATIONUNDO: "VALUATIONUNDO",

	VALUATIONUNDOALL: "VALUATIONUNDOALL",

	VAULTIN: "VAULTIN",

	VAULTINSALES: "VAULTINSALES",

	VAULTINOPS: "VAULTINOPS",

	VERIFY: "VERIFY",

	VIEWHISTORY: "VIEWHISTORY",

	VIEWLEDGER: "VIEWLEDGER",

	VIEWVALUATION: "VIEWVALUATION",

	CREATE: "CREATE",

	NEW: "NEW",

	INSERT: "INSERT",

	UPDATE: "UPDATE",

	COPY: "COPY",

	DELETE: "DELETE",

	LIST: "LIST",

	VIEW: "VIEW",

	EXPORT: "EXPORT",

	PRINT: "PRINT",

	EXECUTE: "EXECUTE",

	EXECUTEALL: "EXECUTEALL",

	CHANGENAME: "CHANGENAME",

	MOVETODATE: "MOVETODATE",

	LOADDATA: "LOADDATA",

	IMPORT: "IMPORT",

	PURGE: "PURGE",

	CHANGEFEE: "CHANGEFEE",

	SETFEESPLIT: "SETFEESPLIT",

	CHANGEFEESETSPLIT: "CHANGEFEESETSPLIT",

	ACTIVATE: "ACTIVATE",

	DEACTIVATE: "DEACTIVATE",

	DISCARDSTALE: "DISCARDSTALE",

	DISCARD: "DISCARD",

	UNDISCARD: "UNDISCARD",

	ARCHIVE: "ARCHIVE",

	UNARCHIVE: "UNARCHIVE",

	APPROVE: "APPROVE",

	UNAPPROVE: "UNAPPROVE",

	RECALL: "RECALL",

	REJECT: "REJECT",

	UNREJECT: "UNREJECT",

	CANCEL: "CANCEL",

	COMPUTEFEES: "COMPUTEFEES",

	FEEPROCESS: "FEEPROCESS",

	FEEUNPROCESS: "FEEUNPROCESS",

	INITDO: "INITDO",

	INITUNDO: "INITUNDO",

	FETCH: "FETCH",

	QUERY: "QUERY",

	INVALIDATE: "INVALIDATE",

	CLIENTAPPROVE: "CLIENTAPPROVE",

	PWDCHANGE: "PWDCHANGE",

	PWDCHANGEOWN: "PWDCHANGEOWN",

	KILL: "KILL",

	CHANGEOWNER: "CHANGEOWNER",

	MERGE: "MERGE",

	ALLOTFULL: "ALLOTFULL",

	AUTOALLOT: "AUTOALLOT",

	MANUALALLOT: "MANUALALLOT",

	ALLOTUNDO: "ALLOTUNDO",

	CLEAR: "CLEAR",

	CLEARALL: "CLEARALL",

	OPEN: "OPEN",

	CLOSE: "CLOSE",

	LOCK: "LOCK",

	UNLOCK: "UNLOCK",

	POST: "POST",

	POSTRANGE: "POSTRANGE",

	PROPAGATE: "PROPAGATE",

	PROCESS: "PROCESS",

	UNPROCESS: "UNPROCESS",

	PUBLISH: "PUBLISH",

	RECATEGORIZE: "RECATEGORIZE",

	RECEIVE: "RECEIVE",

	RECOMPUTE: "RECOMPUTE",

	RECOMPUTEALL: "RECOMPUTEALL",

	REPOST: "REPOST",

	REVERSE: "REVERSE",

	REVIVE: "REVIVE",

	REVERSERANGE: "REVERSERANGE",

	REWIND: "REWIND",

	RUN: "RUN",

	RUNALL: "RUNALL",

	SEND: "SEND",

	SPLIT: "SPLIT",

	SUBMIT: "SUBMIT",

	CHANGE: "CHANGE",

	STATUS: "STATUS",

	SUSPEND: "SUSPEND",

	SYNC: "SYNC",

	TERMINATEDO: "TERMINATEDO",

	ROLLOVERDO: "ROLLOVERDO",

	UNFINALIZE: "UNFINALIZE",

	VALIDATE: "VALIDATE",

	VALIDATEALL: "VALIDATEALL",

	VALIDATEDOWNLOAD: "VALIDATEDOWNLOAD",

	CLEARDOWNLOAD: "CLEARDOWNLOAD",

	IMPORTVALID: "IMPORTVALID",

};
