import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, today, threeMonthsAgo, oneYearAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FABalanceSheetList: {
		...fluxListDefaults,
		actionNamespace: 'FABalanceSheetList',
		entityName: 'FAGlAcctBalance',
		fetchUriTemplate: 'orgcompany(${companyId})/BalanceSheetDetailed',
		exportUriTemplate: 'orgcompany(${companyId})/BalanceSheetDetailed/Export',
		printUriTemplate: 'orgcompany(${companyId})/BalanceSheetDetailed/Print',
		keyField: 'AccountId',
		queryUseRestEndpoint: true,
		sizePage: 100,
		searchFields: [],
		filterValues: {
			searchText: '',
			companyId: 1,
			atDate: toIsoDateString(today)
		},
	},

	FACalloverList: {
		...fluxListDefaults,
		actionNamespace: 'FACalloverList',
		entityName: 'FACallover',
		fetchUriTemplate: 'FACallover/GetCalloverForRange',
		exportUriTemplate: 'FACallover/ExportCalloverForRange',
		printUriTemplate: 'FACallover/PrintCalloverForDate',
		queryUseRestEndpoint: true,
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(today),
			endDate: toIsoDateString(today),
			enumParamEntryType: EnumAllOptionValue,
			enumParamEntryMethod: EnumAllOptionValue,
		},
	},

	FAGlAcctCreationList: {
		...fluxListDefaults,
		actionNamespace: 'FAGlAcctCreationList',
		entityName: 'AuditCreationTO',
		fetchUriTemplate: 'FAGlAcct/CreationLog',
		exportUriTemplate: 'FAGlAcct/CreationLog/Export',
		printUriTemplate: 'FAGlAcct/CreationLog/Print',
		queryUseRestEndpoint: true,
		keyField: 'EntityId',
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
		},
	},

	FAGlHistoryAuditList: {
		...fluxListDefaults,
		actionNamespace: 'FAGlHistoryAuditList',
		entityName: 'FAGlHistoryAudit',
		fetchUriTemplate: 'orgcompany(${companyId})/GetGlAuditHistory',
		exportUriTemplate: 'orgcompany(${companyId})/ExportGlAuditHistory',
		printUriTemplate: 'orgcompany(${companyId})/PrintGlAuditHistory',
		queryUseRestEndpoint: true,
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamEntryType: EnumAllOptionValue,
			enumParamEntryMethod: EnumAllOptionValue,
		},
	},

	FAStatAcctCreationList: {
		...fluxListDefaults,
		actionNamespace: 'FAStatAcctCreationList',
		entityName: 'AuditCreationTO',
		fetchUriTemplate: 'FAStatAcct/CreationLog',
		exportUriTemplate: 'FAStatAcct/CreationLog/Export',
		printUriTemplate: 'FAStatAcct/CreationLog/Print',
		queryUseRestEndpoint: true,
		keyField: 'EntityId',
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
		},
	},
	
	FATrialBalanceList: {
		...fluxListDefaults,
		actionNamespace: 'FATrialBalanceList',
		entityName: 'FAGlTrialBalance',
		fetchUriTemplate: 'orgcompany(${companyId})/TrialBalanceAtDate',
		exportUriTemplate: 'orgcompany(${companyId})/TrialBalanceAtDate/Export',
		printUriTemplate: 'orgcompany(${companyId})/TrialBalanceAtDate/Print',
		queryUseRestEndpoint: true,
		keyField: 'AccountId',
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			excludeZero: 1,
			atDate: toIsoDateString(today)
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FATrialBalanceView: {
		...fluxViewDefaults,
		actionNamespace: 'FATrialBalanceView',
		entityName: 'FATrialBalance',
		fetchUriTemplate: 'FATrialBalance',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
