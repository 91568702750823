import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';

import { fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	ScItemBrandList: {
		...fluxListDefaults,
		actionNamespace: 'ScItemBrandList',
        entityName: 'ScItemBrand',
		fetchUriTemplate: 'ScItemBrand',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			saleClassId: NullEntityId,
		},
	},
	ScItemBrandPick: {
		...fluxPickDefaults,
		actionNamespace: 'ScItemBrandPick',
        entityName: 'ScItemBrand',
		fetchUriTemplate: 'ScItemBrand',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			saleClassId: NullEntityId,
		},
	},

	ScItemGroupList: {
		...fluxListDefaults,
		actionNamespace: 'ScItemGroupList',
        entityName: 'ScItemGroup',
		fetchUriTemplate: 'ScItemGroup',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
	ScItemGroupPick: {
		...fluxPickDefaults,
		actionNamespace: 'ScItemGroupPick',
        entityName: 'ScItemGroup',
		fetchUriTemplate: 'ScItemGroup',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	ScItemList: {
		...fluxListDefaults,
		actionNamespace: 'ScItemList',
        entityName: 'ScItem',
		fetchUriTemplate: 'ScItem',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			brandId: NullEntityId,
			saleClassId: NullEntityId,
			groupId: NullEntityId,
		},
	},
	ScItemPick: {
		...fluxPickDefaults,
		actionNamespace: 'ScItemPick',
        entityName: 'ScItem',
		fetchUriTemplate: 'ScItem',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	ScItemGroupView: {
		...fluxViewDefaults,
		actionNamespace: 'ScItemGroupView',
		entityName: 'ScItemGroup',
		fetchUriTemplate: 'ScItemGroup',
		queryUseRestEndpoint: true,
	},

	ScItemView: {
		...fluxViewDefaults,
		actionNamespace: 'ScItemView',
		entityName: 'ScItem',
		fetchUriTemplate: 'ScItem',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
