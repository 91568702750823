import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { SecInstrumentState } from 'app-model/enums';
import { PartnerState } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, tenYearsAgo, today, twentyYearsAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxPartnerLocationSubListDefaults, fluxPartnerRelationshipSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	BondList: {
		...fluxListDefaults,
		actionNamespace: 'BondList',
		entityName: 'Bond',
		fetchUriTemplate: 'Bond',
		exportUriTemplate: 'Bond/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			ListExchangeId: NullEntityId,
			closureType: SecInstrumentState.Active,
		},
	},
	BondPick: {
        ...fluxPickDefaults,
		actionNamespace: 'BondPick',
		entityName: 'Bond',
		fetchUriTemplate: 'Bond',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	CmpTradeFeeList: {
		...fluxListDefaults,
		actionNamespace: 'CmpTradeFeeList',
		entityName: 'CmpTradeFee',
		fetchUriTemplate: 'CmpTradeFee/Prevailing',
		exportUriTemplate: 'CmpTradeFee/Prevailing/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			exchangeId: NullEntityId,
			atDate: toIsoDateString(today),
			enumParamInstrumentType: EnumAllOptionValue,
		},
	},

	EQConfigList: {
		...fluxListDefaults,
		actionNamespace: 'EQConfigList',
		entityName: 'EQConfig',
		fetchUriTemplate: 'OrgCompany(${companyId})/EQConfigs',
		exportUriTemplate: 'OrgCompany(${companyId})/EQConfigs/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
		},
	},

	EQExchangeTradeAcctSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQExchangeTradeAcctSubList',
		entityName: 'EQExchangeTradeAcct',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/TradeAccts',
		exportUriTemplate: 'RefExchangeDef(${subListParentId})/TradeAccts/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQExchangeTradeRuleSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQExchangeTradeRuleSubList',
		entityName: 'EQExchangeTradeRule',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/TradeRules',
		exportUriTemplate: 'RefExchangeDef(${subListParentId})/TradeRules/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
		},
	},

	EQIndexList: {
		...fluxListDefaults,
		actionNamespace: 'EQIndexList',
		entityName: 'EQIndex',
		fetchUriTemplate: 'EQIndex',
		exportUriTemplate: 'EQIndex/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			ListExchangeId: NullEntityId,
			closureType: SecInstrumentState.Active,
		},
	},
	EQIndexPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQIndexPick',
		entityName: 'EQIndex',
		fetchUriTemplate: 'EQIndex',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQInstrumentList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentList',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		exportUriTemplate: 'EQInstrument/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			InstrumentClass: EnumAllOptionValue,
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			ListExchangeId: NullEntityId,
			closureType: SecInstrumentState.Active,
		},
	},
	EQInstrumentPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQInstrumentPick',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
	EQInstrumentSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentSubList',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/EQInstrument',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			closureType: SecInstrumentState.Active,
		},
	},

	EQInstrumentConvertibleSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentConvertibleSubList',
		entityName: 'EQInstrumentConvertible',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/Convertibles',
		exportUriTemplate: 'EQInstrument(${subListParentId})/Convertibles/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentFeeSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentFeeSubList',
		entityName: 'EQInstrumentFee',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/Fees',
		exportUriTemplate: 'EQInstrument(${subListParentId})/Fees/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			tradeType: EnumAllOptionValue,
		},
	},

	EQInstrumentMarketMakerSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentMarketMakerSubList',
		entityName: 'EQInstrumentMarketMaker',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/MarketMakers',
		exportUriTemplate: 'EQInstrument(${subListParentId})/MarketMakers/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentOldCodeSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentOldCodeSubList',
		entityName: 'EQInstrumentOldCode',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/OldCodes',
		exportUriTemplate: 'EQInstrument(${subListParentId})/OldCodes/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(twentyYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentOutstandingSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentOutstandingSubList',
		entityName: 'EQInstrumentOutstanding',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/Outstandings',
		exportUriTemplate: 'EQInstrument(${subListParentId})/Outstandings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentPriceSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentPriceSubList',
		entityName: 'EQInstrumentPrice',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/Prices',
		exportUriTemplate: 'EQInstrument(${subListParentId})/Prices/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQMarketOperatorList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketOperatorList',
		entityName: 'EQMarketOperator',
		fetchUriTemplate: 'EQMarketOperator',
		exportUriTemplate: 'EQMarketOperator/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			classCode: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	EQMarketOperatorPick: {
		...fluxPickDefaults,
		actionNamespace: 'EQMarketOperatorPick',
		entityName: 'EQMarketOperator',
		fetchUriTemplate: 'EQMarketOperator',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQMarketOperatorLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'EQMarketOperatorLocationSubList',
		cmdUriTemplate: 'EQMarketOperator(${subListParentId})/Locations',
		fetchUriTemplate: 'EQMarketOperator(${subListParentId})/Locations',
		exportUriTemplate: 'EQMarketOperator(${subListParentId})/Locations/Export',
	},

	EQMarketOperatorRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'EQMarketOperatorRelationshipSubList',
		cmdUriTemplate: 'EQMarketOperator(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'EQMarketOperator(${subListParentId})/RelatedParties',
		exportUriTemplate: 'EQMarketOperator(${subListParentId})/RelatedParties/Export',
	},

	EQTradeFeeList: {
        ...fluxListDefaults,
		actionNamespace: 'EQTradeFeeList',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'EQTradeFee',
		exportUriTemplate: 'EQTradeFee/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			ExchangeId: NullEntityId,
			InstrType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
		},
	},
	EQTradeFeePick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQTradeFeePick',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'EQTradeFee',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQTradeFeeAcctSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQTradeFeeAcctSubList',
		entityName: 'EQTradeFeeAcct',
		fetchUriTemplate: 'EQTradeFee(${subListParentId})/FeeAccts',
		exportUriTemplate: 'EQTradeFee(${subListParentId})/FeeAccts/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(twentyYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQTradeFeeValueSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQTradeFeeValueSubList',
		entityName: 'EQTradeFeeValue',
		fetchUriTemplate: 'EQTradeFee(${subListParentId})/FeeValues',
		exportUriTemplate: 'EQTradeFee(${subListParentId})/FeeValues/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(twentyYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EquityList: {
		...fluxListDefaults,
		actionNamespace: 'EquityList',
		entityName: 'Equity',
		fetchUriTemplate: 'Equity',
		exportUriTemplate: 'Equity/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			ListExchangeId: NullEntityId,
			closureType: SecInstrumentState.Active,
		},
	},
	EquityPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EquityPick',
		entityName: 'Equity',
		fetchUriTemplate: 'Equity',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	RefExchangeBoardPick: {
		...fluxPickDefaults,
		actionNamespace: 'RefExchangeBoardPick',
		entityName: 'RefExchangeBoard',
		fetchUriTemplate: 'RefExchangeDef(${parentId})/RefExchangeBoards',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			parentId: -1,
		},
	},
	RefExchangeBoardSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefExchangeBoardSubList',
		entityName: 'RefExchangeBoard',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RefExchangeBoards',
		exportUriTemplate: 'RefExchangeDef(${subListParentId})/RefExchangeBoards/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	RefExchangeDefList: {
        ...fluxListDefaults,
		actionNamespace: 'RefExchangeDefList',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		exportUriTemplate: 'RefExchangeDef/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			country: NullEntityId,
			exchangeType: EnumAllOptionValue,
			exchangeOperations: 'ResearchReporting',
		},
	},
	RefExchangeDefPick: {
        ...fluxPickDefaults,
		actionNamespace: 'RefExchangeDefPick',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	RefExchangeDefLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'RefExchangeDefLocationSubList',
		cmdUriTemplate: 'RefExchangeDef(${subListParentId})/Locations',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/Locations',
		exportUriTemplate: 'RefExchangeDef(${subListParentId})/Locations/Export',
	},

	RefExchangeDefRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'RefExchangeDefRelationshipSubList',
		cmdUriTemplate: 'RefExchangeDef(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RelatedParties',
		exportUriTemplate: 'RefExchangeDef(${subListParentId})/RelatedParties/Export',
	},

	RefExchangeUserSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefExchangeUserSubList',
		entityName: 'RefExchangeUser',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RefExchangeUsers',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	RefSectorDefPick: {
		...fluxListDefaults,
		actionNamespace: 'RefSectorDefSubList',
		entityName: 'RefSectorDef',
		fetchUriTemplate: 'RefExchangeDef(${parentId})/RefSectorDefs',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			parentId: -1,
		},
	},
	RefSectorDefSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefSectorDefSubList',
		entityName: 'RefSectorDef',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RefSectorDefs',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			sectorType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	BondView: {
		...fluxViewDefaults,
		actionNamespace: 'BondView',
		entityName: 'Bond',
		fetchUriTemplate: 'Bond',
		queryUseRestEndpoint: true,
	},

	EQConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'EQConfigView',
		entityName: 'EQConfig',
		fetchUriTemplate: 'EQConfig',
		queryUseRestEndpoint: true,
	},

	EQIndexView: {
		...fluxViewDefaults,
		actionNamespace: 'EQIndexView',
		entityName: 'EQIndex',
		fetchUriTemplate: 'EQIndex',
		queryUseRestEndpoint: true,
	},

	EQInstrumentPriceView: {
		...fluxViewDefaults,
		actionNamespace: 'EQInstrumentPriceView',
		entityName: 'EQInstrumentPrice',
		fetchUriTemplate: 'EQInstrumentPrice',
		queryUseRestEndpoint: true,
	},

	EQMarketOperatorView: {
		...fluxViewDefaults,
		actionNamespace: 'EQMarketOperatorView',
		entityName: 'EQMarketOperator',
		fetchUriTemplate: 'EQMarketOperator',
		queryUseRestEndpoint: true,
	},

	EQTradeFeeView: {
		...fluxViewDefaults,
		actionNamespace: 'EQTradeFeeView',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'EQTradeFee',
		queryUseRestEndpoint: true,
	},

	EquityView: {
		...fluxViewDefaults,
		actionNamespace: 'EquityView',
		entityName: 'Equity',
		fetchUriTemplate: 'Equity',
		queryUseRestEndpoint: true,
	},

	RefExchangeDefView: {
		...fluxViewDefaults,
		actionNamespace: 'RefExchangeDefView',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
