import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { OrgCompanyType, ReferenceDataClosure } from 'app-model/enums';
import { NullEntityId } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	OrgCompanyList: {
		...fluxListDefaults,
		actionNamespace: 'OrgCompanyList',
		entityName: 'OrgCompany',
		fetchUriTemplate: 'OrgCompany',
		exportUriTemplate: 'OrgCompany/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			coOrgType: OrgCompanyType.Firm,
			closureType: ReferenceDataClosure.Active,
		},
	},
	OrgCompanyPick: {
		...fluxPickDefaults,
		actionNamespace: 'OrgCompanyPick',
		entityName: 'OrgCompany',
		fetchUriTemplate: 'OrgCompany',
		delayFetch: false,
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	OrgLocationList: {
		...fluxListDefaults,
		actionNamespace: 'OrgLocationList',
		entityName: 'OrgLocation',
		fetchUriTemplate: 'OrgLocation',
		exportUriTemplate: 'OrgLocation/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: ReferenceDataClosure.Active,
		},
	},
	OrgLocationPick: {
		...fluxPickDefaults,
		actionNamespace: 'OrgLocationPick',
		entityName: 'OrgLocation',
		fetchUriTemplate: 'OrgLocation',
		delayFetch: false,
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	OrgRegionList: {
		...fluxListDefaults,
		actionNamespace: 'OrgRegionList',
		entityName: 'OrgRegion',
		fetchUriTemplate: 'OrgRegion',
		exportUriTemplate: 'OrgRegion/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: ReferenceDataClosure.Active,
		},
	},
	OrgRegionPick: {
		...fluxPickDefaults,
		actionNamespace: 'OrgRegionPick',
		entityName: 'OrgRegion',
		fetchUriTemplate: 'OrgRegion',
		delayFetch: false,
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	OrgStoreList: {
		...fluxListDefaults,
		actionNamespace: 'OrgStoreList',
		entityName: 'OrgStore',
		fetchUriTemplate: 'OrgStore',
		exportUriTemplate: 'OrgStore/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			closureType: ReferenceDataClosure.Active,
		},
	},
	OrgStorePick: {
		...fluxPickDefaults,
		actionNamespace: 'OrgStorePick',
		entityName: 'OrgStore',
		fetchUriTemplate: 'OrgStore',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	OrgUnitList: {
		...fluxListDefaults,
		actionNamespace: 'OrgUnitList',
		entityName: 'OrgUnit',
		fetchUriTemplate: 'OrgUnit',
		exportUriTemplate: 'OrgUnit/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: ReferenceDataClosure.Active,
		},
	},
	OrgUnitPick: {
		...fluxPickDefaults,
		actionNamespace: 'OrgUnitPick',
		entityName: 'OrgUnit',
		fetchUriTemplate: 'OrgUnit',
		delayFetch: false,
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	OrgCompanyView: {
		...fluxViewDefaults,
		actionNamespace: 'OrgCompanyView',
		entityName: 'OrgCompany',
		fetchUriTemplate: 'OrgCompany',
		queryUseRestEndpoint: true,
	},
	OrgLocationView: {
		...fluxViewDefaults,
		actionNamespace: 'OrgLocationView',
		entityName: 'OrgLocation',
		fetchUriTemplate: 'OrgLocation',
		queryUseRestEndpoint: true,
	},
	OrgRegionView: {
		...fluxViewDefaults,
		actionNamespace: 'OrgRegionView',
		entityName: 'OrgRegion',
		fetchUriTemplate: 'OrgRegion',
		queryUseRestEndpoint: true,
	},
	OrgStoreView: {
		...fluxViewDefaults,
		actionNamespace: 'OrgStoreView',
		entityName: 'OrgStore',
		fetchUriTemplate: 'OrgStore',
		queryUseRestEndpoint: true,
	},
	OrgUnitView: {
		...fluxViewDefaults,
		actionNamespace: 'OrgUnitView',
		entityName: 'OrgUnit',
		fetchUriTemplate: 'OrgUnit',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
