import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId, today, oneMonthAgo, threeMonthsAgo, oneYearAgo } from 'app-model/ModelConstants';
import { GeneralDataClosure, PartnerEntityName } from 'app-model/enums';
import { MsgClosure } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CustomerBirthdayTOList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerBirthdayTOList',
		entityName: 'CustomerBirthdayTO',
		fetchUriTemplate: 'CustomerBirthdayTO/GetUpcoming',
		exportUriTemplate: 'CustomerBirthdayTO/ExportUpcoming',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			staffId: NullEntityId
		},
	},

	CustomerCallNoteList: {
        ...fluxListDefaults,
		actionNamespace: 'CustomerCallNoteList',
        entityName: 'PartnerCallNote',
		fetchUriTemplate: 'PartnerCallNote',
		orderBy: 'CallDate desc, PartnerMaster/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'PartnerMaster($select=ClassCode,PartnerNo,PartnerLabel)',
        ],
		searchFields: ['Notes', 'PartnerMaster/PartnerNo', 'PartnerMaster/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			partnerSubClassId: NullEntityId,
			callType: EnumAllOptionValue,
			closureType: GeneralDataClosure.Pending,
		},
	},
	
 	CustomerDefaultSubscriptionList: {
        ...fluxListDefaults,
		actionNamespace: 'CustomerDefaultSubscriptionList',
        entityName: 'NFDefaultSubscription',
		fetchUriTemplate: 'NFDefaultSubscription',
		exportUriTemplate: 'NFDefaultSubscription/ExportList',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			classCode: PartnerEntityName.Customer,
			notifyTypeId: NullEntityId,
		},
    },
    
	CustomerMessageList: {
        ...fluxListDefaults,
		actionNamespace: 'CustomerMessageList',
        entityName: 'NFMessage',
		fetchUriTemplate: 'Customer/Messages',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			partnerSubClassId: NullEntityId,
			notifyTypeId: NullEntityId,
			deliveryMethod: EnumAllOptionValue,
			closureType: MsgClosure.ClearedForSend,
		},
    },
    
	CustomerSubscriptionList: {
        ...fluxListDefaults,
		actionNamespace: 'CustomerSubscriptionList',
        entityName: 'NFSubscription',
		fetchUriTemplate: 'NFSubscription',
		exportUriTemplate: 'NFSubscription/ExportList',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			classCode: PartnerEntityName.Customer,
			partnerSubClassId: NullEntityId,
			notifyTypeId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},
    
	NFBulkSubscriptionList: {
        ...fluxListDefaults,
		actionNamespace: 'NFBulkSubscriptionList',
        entityName: 'NFBulkSubscription',
		fetchUriTemplate: 'NFBulkSubscription',
		exportUriTemplate: 'NFBulkSubscription/ExportList',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			classCode: PartnerEntityName.Customer,
			partnerSubClassId: NullEntityId,
			notifyTypeId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	CustomerCallNoteView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerCallNoteView',
		entityName: 'PartnerCallNote',
		fetchUriTemplate: 'PartnerCallNote',
		selectFields: [],
		expandProps: [
			'PartnerMaster($select=TranEntityType,PartnerNo,PartnerLabel)',
			'ParticipantList($expand=Participant($select=PartnerNo,PartnerLabel))',
		],
	},

	CustomerMessageView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerMessageView',
		entityName: 'NFMessage',
		fetchUriTemplate: 'NFMessage',
		selectFields: [],
		expandProps: [
            'Recipient($select=TranEntityType,PartnerNo,PartnerLabel)',
			'NFMessageAttachments',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
