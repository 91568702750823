import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { HRClosure } from 'app-model/enums';

const stLists : IListComponentControlList = {
	HRLeaveAnnualExclusionSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveAnnualExclusionSubList',
		entityName: 'HRLeaveAnnualExclusion',
		fetchUriTemplate: 'HRLeaveYear(${subListParentId})/AnnualExclusions',
		exportUriTemplate: 'HRLeaveYear(${subListParentId})/AnnualExclusions/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	HRLeaveList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveList',
		entityName: 'HRLeave',
		fetchUriTemplate: 'hrleaveyear(${leaveYearId})/Leave',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			leaveType: EnumAllOptionValue,
			assignment: EnumAllOptionValue,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	HRLeaveSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveSubList',
		entityName: 'HRLeave',
		fetchUriTemplate: 'hrleaveyear(${subListParentId})/Leave',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			leaveType: EnumAllOptionValue,
			assignment: EnumAllOptionValue,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	HRLeaveDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRLeaveDocumentSubList',
		fetchUriTemplate: 'HRLeave(${subListParentId})/Documents',
		exportUriTemplate: 'HRLeave(${subListParentId})/Documents(${entityId})/Export',
	},

	HRLeaveWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRLeaveWorkflowHistorySubList',
		fetchUriTemplate: 'HRLeave(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'HRLeave(${subListParentId})/WorkflowHistory/Export',
	},

	HRLeaveQuotaList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveQuotaList',
		entityName: 'HRLeaveQuota',
		fetchUriTemplate: 'HRLeaveQuota',
		exportUriTemplate: 'HRLeaveQuota/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			hierarchyId: NullEntityId,
			category: EnumAllOptionValue,
			leaveType: EnumAllOptionValue,
			closureType: HRClosure.Running,
		},
	},

	HRLeaveRotaList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveRotaList',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'hrleaveyear(${leaveYearId})/LeaveRota',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			assignment: EnumAllOptionValue,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	HRLeaveRotaSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveRotaSubList',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'hrleaveyear(${subListParentId})/LeaveRota',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			assignment: EnumAllOptionValue,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	HRLeaveRotaDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRLeaveRotaDocumentSubList',
		fetchUriTemplate: 'HRLeaveRota(${subListParentId})/Documents',
		exportUriTemplate: 'HRLeaveRota(${subListParentId})/Documents(${entityId})/Export',
	},

	HRLeaveRotaWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRLeaveRotaWorkflowHistorySubList',
		fetchUriTemplate: 'HRLeaveRota(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'HRLeaveRota(${subListParentId})/WorkflowHistory/Export',
	},

	HRLeaveYearQuotaSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveYearQuotaSubList',
		entityName: 'HRLeaveYearQuota',
		fetchUriTemplate: 'HRLeaveYear(${subListParentId})/LeaveQuotas',
		exportUriTemplate: 'HRLeaveYear(${subListParentId})/LeaveQuotas/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			leaveType: EnumAllOptionValue,
		},
	},

	HRLeaveYearList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveYearList',
		entityName: 'HRLeaveYear',
		fetchUriTemplate: 'HRLeaveYear',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRLeaveView: {
		...fluxViewDefaults,
		actionNamespace: 'HRLeaveView',
		entityName: 'HRLeave',
		fetchUriTemplate: 'HRLeave',
		queryUseRestEndpoint: true,
	},

	HRLeaveRotaView: {
		...fluxViewDefaults,
		actionNamespace: 'HRLeaveRotaView',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'HRLeaveRota',
		queryUseRestEndpoint: true,
	},

	HRLeaveYearView: {
		...fluxViewDefaults,
		actionNamespace: 'HRLeaveYearView',
		entityName: 'HRLeaveYear',
		fetchUriTemplate: 'HRLeaveYear',
		selectFields: [],
		expandProps: [
			'HRApprover($select=StaffNo,StaffName)',
			'MgtApprover($select=StaffNo,StaffName)',
			'HRLeaveYearQuotas($expand=HRStaff($select=StaffNo,StaffName);$orderby=HRStaff/StaffName)',
			'HRLeaveAnnualExclusions',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
