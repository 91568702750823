import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, tenYearsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	EQCorpActionDividendList: {
        ...fluxListDefaults,
		actionNamespace: 'EQCorpActionDividendList',
		entityName: 'EQCorpActionDividend',
		fetchUriTemplate: 'EQCorpActionDividend',
		exportUriTemplate: 'EQCorpActionDividend/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQCorpActionDividendPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQCorpActionDividendPick',
		entityName: 'EQCorpActionDividend',
		fetchUriTemplate: 'EQCorpActionDividend',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQCorpActionMergerList: {
        ...fluxListDefaults,
		actionNamespace: 'EQCorpActionMergerList',
		entityName: 'EQCorpActionMerger',
		fetchUriTemplate: 'EQCorpActionMerger',
		exportUriTemplate: 'EQCorpActionMerger/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQCorpActionMergerPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQCorpActionMergerPick',
		entityName: 'EQCorpActionMerger',
		fetchUriTemplate: 'EQCorpActionMerger',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQCorpActionSplitList: {
        ...fluxListDefaults,
		actionNamespace: 'EQCorpActionSplitList',
		entityName: 'EQCorpActionSplit',
		fetchUriTemplate: 'EQCorpActionSplit',
		exportUriTemplate: 'EQCorpActionSplit/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQCorpActionSplitPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQCorpActionSplitPick',
		entityName: 'EQCorpActionSplit',
		fetchUriTemplate: 'EQCorpActionSplit',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQDelistList: {
		...fluxListDefaults,
		actionNamespace: 'EQDelistList',
		entityName: 'EQDelist',
		fetchUriTemplate: 'EQDelist',
		exportUriTemplate: 'EQDelist/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQDelistPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQDelistPick',
		entityName: 'EQDelist',
		fetchUriTemplate: 'EQDelist',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQDelistCertificateSubList: {
		...fluxPickDefaults,
		actionNamespace: 'EQDelistCertificateSubList',
		entityName: 'EQDelistCertificate',
		fetchUriTemplate: 'EQDelist(${subListParentId})/Certificates',
		exportUriTemplate: 'EQDelist(${subListParentId})/Certificates/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQDelistHoldingSubList: {
		...fluxPickDefaults,
		actionNamespace: 'EQDelistHoldingSubList',
		entityName: 'EQDelistHolding',
		fetchUriTemplate: 'EQDelist(${subListParentId})/Holdings',
		exportUriTemplate: 'EQDelist(${subListParentId})/Holdings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQHoldingDividendList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingDividendList',
		entityName: 'EQHoldingDividend',
		fetchUriTemplate: 'EQHoldingDividend',
		exportUriTemplate: 'EQHoldingDividend/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQHoldingDividendSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingDividendSubList',
		entityName: 'EQHoldingDividend',
		fetchUriTemplate: 'EQCorpActionDividend(${subListParentId})/Holdings',
		exportUriTemplate: 'EQCorpActionDividend(${subListParentId})/Holdings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQHoldingMergerList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingMergerList',
		entityName: 'EQHoldingMerger',
		fetchUriTemplate: 'EQHoldingMerger',
		exportUriTemplate: 'EQHoldingMerger/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQHoldingMergerSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingMergerSubList',
		entityName: 'EQHoldingMerger',
		fetchUriTemplate: 'EQCorpActionMerger(${subListParentId})/Holdings',
		exportUriTemplate: 'EQCorpActionMerger(${subListParentId})/Holdings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQHoldingSplitList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingSplitList',
		entityName: 'EQHoldingSplit',
		fetchUriTemplate: 'EQHoldingSplit',
		exportUriTemplate: 'EQHoldingSplit/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQHoldingSplitSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingSplitSubList',
		entityName: 'EQHoldingSplit',
		fetchUriTemplate: 'EQCorpActionSplit(${subListParentId})/Holdings',
		exportUriTemplate: 'EQCorpActionSplit(${subListParentId})/Holdings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}


const stViews : IViewComponentControlList = {
	EQCorpActionDividendView: {
        ...fluxViewDefaults,
		actionNamespace: 'EQCorpActionDividendView',
		entityName: 'EQCorpActionDividend',
		fetchUriTemplate: 'EQCorpActionDividend',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType)'],
	},

	EQCorpActionMergerView: {
        ...fluxViewDefaults,
		actionNamespace: 'EQCorpActionMergerView',
		entityName: 'EQCorpActionMerger',
		fetchUriTemplate: 'EQCorpActionMerger',
		selectFields: [],
		expandProps: [
			'EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType)'
		],
	},

	EQCorpActionPryIssueView: {
        ...fluxViewDefaults,
		actionNamespace: 'EQCorpActionPryIssueView',
		entityName: 'EQCorpActionPryIssue',
		fetchUriTemplate: 'EQCorpActionPryIssue',
		selectFields: [],
		expandProps: [
			'EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType)',
			'CollatingCompany($select=CoShortCode,CoName)',
			'EQCorpActionPryIssueGlAccts($expand=OrgCompany($select=CoShortCode,CoName)'
			+ ',OfferAcct($select=AcctNo,AcctTitle),OfferWalkInCustomer($select=PartnerNo,PartnerLabel)'
			+ ',RightAcct($select=AcctNo,AcctTitle),RightWalkInCustomer($select=PartnerNo,PartnerLabel)'
			+ ',ChargesAcct($select=AcctNo,AcctTitle),PaymentDiffAcct($select=AcctNo,AcctTitle))',
		],
	},

	EQCorpActionSplitView: {
        ...fluxViewDefaults,
		actionNamespace: 'EQCorpActionSplitView',
		entityName: 'EQCorpActionSplit',
		fetchUriTemplate: 'EQCorpActionSplit',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType)'],
	},

	EQDelistView: {
        ...fluxViewDefaults,
		actionNamespace: 'EQDelistView',
		entityName: 'EQDelist',
		fetchUriTemplate: 'EQDelist',
		selectFields: [],
        expandProps: [
            'EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType)',
        ],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
