import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { SysActions } from 'app-model/SysActions';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FABasisList: {
		...fluxListDefaults,
		actionNamespace: 'FABasisList',
		entityName: 'FABasis',
		fetchUriTemplate: 'orgcompany(${companyId})/Basis',
		exportUriTemplate: 'orgcompany(${companyId})/Basis/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
		},
	},

	FABasisPeriodSubList: {
		...fluxListDefaults,
		actionNamespace: 'FABasisPeriodSubList',
		entityName: 'FABasisPeriod',
		fetchUriTemplate: 'FABasis(${subListParentId})/Periods',
		exportUriTemplate: 'FABasis(${subListParentId})/Periods/Export',
		//cmdUriTemplate: 'FAJrnlGlPage(${subListParentId})/Entries',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	FACenterList: {
		...fluxListDefaults,
		actionNamespace: 'FACenterList',
		entityName: 'FACenter',
		fetchUriTemplate: 'FACenter',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
	FACenterPick: {
		...fluxPickDefaults,
		actionNamespace: 'FACenterPick',
		entityName: 'FACenter',
		fetchUriTemplate: 'FACenter',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	FAConfigList: {
		...fluxListDefaults,
		actionNamespace: 'FAConfigList',
        entityName: 'FAConfig',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgFAConfig',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
		},
	},

	FAPaymentProcessorList: {
        ...fluxListDefaults,
		actionNamespace: 'FAPaymentProcessorList',
		entityName: 'FAPaymentProcessor',
		fetchUriTemplate: 'FAPaymentProcessor',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: 'Approved',
		},
	},
	FAPaymentProcessorPick: {
        ...fluxPickDefaults,
		actionNamespace: 'FAPaymentProcessorPick',
		entityName: 'FAPaymentProcessor',
		fetchUriTemplate: 'FAPaymentProcessor',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FABasisView: {
		...fluxViewDefaults,
		actionNamespace: 'FABasisView',
		entityName: 'FABasis',
		fetchUriTemplate: 'FABasis',
		queryUseRestEndpoint: true,
	},

	FACenterView: {
		...fluxViewDefaults,
		actionNamespace: 'FACenterView',
		entityName: 'FACenter',
		fetchUriTemplate: 'FACenter',
		queryUseRestEndpoint: true,
	},

	FAConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'FAConfigView',
		entityName: 'FAConfig',
		fetchUriTemplate: 'FAConfig',
		queryUseRestEndpoint: true,
	},

	FAPaymentProcessorView: {
		...fluxViewDefaults,
		actionNamespace: 'FAPaymentProcessorView',
		entityName: 'FAPaymentProcessor',
		fetchUriTemplate: 'FAPaymentProcessor',
		queryUseRestEndpoint: true,
		selectFields: [],
		expandProps: ['Configs'],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
