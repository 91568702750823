import { FluentIconNames } from "app-model/FluentIconNames";

import { SysActions } from "./SysActionDefn";

export const SysActionFluentText = {
	[SysActions.ACTIVATE]: "Activate",
	[SysActions.ADDNOTES]: "Add Notes",

	[SysActions.ADVANCE]: "Advance",

	[SysActions.ALLOTFULL]: "Allot Fully",

	[SysActions.APPLYTEMPLATE]: "Apply Template",
	[SysActions.APPROVE]: "Approve",

	[SysActions.ARCHIVEDOWNLOAD]: "Archive Download",

	[SysActions.AUDITINTERNAL]: "AUDITINTERNAL",
	[SysActions.AUDITEXTERNAL]: "AUDITEXTERNAL",

	[SysActions.AUTOALLOT]: "Auto Allot",

	[SysActions.CANCELORDERS]: "Cancel Orders",

	[SysActions.CERTRECEIVE]: "Receive Certificate",

	[SysActions.CHANGE]: "Change",

	[SysActions.CHANGEACCT]: "Change Acct",

	[SysActions.CHANGEASSIGNEE]: "Change Assignee",
	[SysActions.CHANGEFEE]: "Change Fee",
	[SysActions.CHANGEFEESETSPLIT]: "Change Fee & Split",
	[SysActions.CHANGEOWNER]: "Change Owner",
	[SysActions.CHANGEPARENT]: "Change Parent",
	[SysActions.CHANGEPOSITION]: "Change Position",
	[SysActions.CHANGEPAY]: "Change Pay/Grade",

	[SysActions.CHANGERATE]: "Change Rate",

	[SysActions.CHANGETEMPLATE]: "Change Template",
	[SysActions.CHANGEUSERNAMEEMAIL]: "Change UserName & Email",

	[SysActions.CLEAR]: "Clear",
	[SysActions.CLEARALL]: "Clear All",

	[SysActions.CLEARDOWNLOAD]: "Clear Download",

	[SysActions.CLOSE]: "Close",

	[SysActions.CONVERT]: "Convert",

	[SysActions.COPY]: "Copy",

	[SysActions.COSTAUTOSETUP]: "AutoSetup Cost",
	[SysActions.COSTAUTOSETUPALL]: "AutoSetup All Cost",
	[SysActions.COSTREBUILD]: "Rebuild Cost",
	[SysActions.COSTREBUILDALL]: "Rebuild All Cost",

	[SysActions.CREATE]: "New",

	[SysActions.CREATECASE]: "Create Case",

	[SysActions.CREATEMISSING]: "Create Missing",

	[SysActions.DEACTIVATE]: "Deactivate",

	[SysActions.DEALALLOTAPPROVE]: "Approve Allotment",
	[SysActions.DEALALLOTAUTO]: "Auto Allot",
	[SysActions.DEALALLOTJOB]: "Allot Jobbing",
	[SysActions.DEALALLOTRESEND]: "Resend Allotment",
	[SysActions.DEALALLOTSEND]: "Send Allotment",
	[SysActions.DEALALLOTUNAPPROVE]: "Revoke Allot. Approval",
	[SysActions.DEALALLOTUNDO]: "Undo Allotment",

	[SysActions.DELETE]: "Delete",

	[SysActions.DEMOBILIZE]: "Demobilize",

	[SysActions.DEPRECIATE]: "Depreciate",
	[SysActions.DEPRECIATEALL]: "Depreciate All",

	[SysActions.DISBURSE]: "Disburse",

	[SysActions.DISCARD]: "Discard",
	[SysActions.DISCARDSTALE]: "Discard Stale Items",

	[SysActions.DISPATCH]: "Dispatch",

	[SysActions.DISPOSE]: "Dispose",

	[SysActions.DOCUMENTADD]: "Add Document",
	[SysActions.DOCUMENTCHECK]: "Check Documents",
	[SysActions.DOCUMENTVERIFY]: "Verify Documentation",
	[SysActions.DOCUMENTVOID]: "Void Document",

	[SysActions.ERECEIVE]: "E-Receipt",

	[SysActions.EXPORT]: "Export",

	[SysActions.FAREPOST]: "FAREPOST",

	[SysActions.FETCH]: "Fetch",

	[SysActions.FILTER]: "Filter",

	[SysActions.HANDOVER]: "Hand Over",

	[SysActions.HRCOMMUNICATE]: "HRCOMMUNICATE",

	[SysActions.IMPORTVALID]: "Import Valid",

	[SysActions.INITDO]: "Initialize",
	[SysActions.INITUNDO]: "Uninitialize",
	[SysActions.INTERESTRUN]: "Run Interest",
	[SysActions.INTERESTRUNALL]: "Run All Interest",
	[SysActions.INTERESTREWIND]: "Rewind Interest",
	[SysActions.INTERESTREWINDALL]: "Rewind All Interest",
	[SysActions.INVALIDATE]: "Invalidate",

	[SysActions.KILL]: "Kill",

	[SysActions.LINK]: "Link",

	[SysActions.LIST]: "List",

	[SysActions.LIST_CHANGEFILTER]: "LIST_CHANGEFILTER",
	[SysActions.LIST_CHANGEPAGE]: "LIST_CHANGEPAGE",
	[SysActions.LIST_CHANGESORT]: "LIST_CHANGESORT",
	[SysActions.LIST_FETCHDATA]: "LIST_FETCHDATA",
	[SysActions.LIST_RECEIVEDATA]: "LIST_RECEIVEDATA",
	[SysActions.LIST_RESET]: "LIST_RESET",
	[SysActions.LIST_SELECTITEMS]: "LIST_SELECTITEMS",

	[SysActions.LOADDATA]: "Load Data",

	[SysActions.LOADDEALS]: "Load Deals",

	[SysActions.LOCK]: "Lock",

	[SysActions.LOGCALL]: "Log a Call",

	[SysActions.LOGOUTFIX]: "FIX Logout",

	[SysActions.MANUALDEALALLOT]: "Manual Allotment",

	[SysActions.MARKETDATA]: "Market Data",

	[SysActions.MERGE]: "Merge",

	[SysActions.MIGRATECOMPANY]: "Migrate to Company",

	[SysActions.MGTREVIEW]: "Mgt Review",

	[SysActions.MOVESTORE]: "Move Store",

	[SysActions.MOVETODATE]: "Move to Date",

	[SysActions.NEXTNUM]: "Next Number",

	[SysActions.OPEN]: "Open",
	[SysActions.POST]: "Post",
	[SysActions.POSTRANGE]: "Post Range",

	[SysActions.PRINT]: "Print",

	[SysActions.PROCESS]: "Process",
	[SysActions.PROCESSORDERS]: "Process Orders",

	[SysActions.PROPAGATE]: "Propagate",

	[SysActions.PURGE]: "Purge",
	[SysActions.PURGEDEALS]: "Purge Deals",

	[SysActions.PWDCHANGE]: "Change Password",
	[SysActions.PWDCHANGEOWN]: "Change Own Password",

	[SysActions.RECALL]: "Recall",
	[SysActions.RECATEGORIZE]: "Recategorize",

	[SysActions.RECEIVE]: "Receive",

	[SysActions.RECOGNIZE]: "Recognize",
	[SysActions.RECOMPUTE]: "Recompute",
	[SysActions.RECOMPUTEALL]: "Recompute All",

	[SysActions.REFINANCE]: "Refinance",

	[SysActions.REFRESH]: "Refresh",
	[SysActions.REJECT]: "Reject",

	[SysActions.REPOST]: "Repost",

	[SysActions.RESEND]: "Resend",

	[SysActions.RESPOND]: "Respond",

	[SysActions.RETRIEVE]: "Retrieve",
	[SysActions.RETURNOWNER]: "Return to Owner",

	[SysActions.REVERSE]: "Reverse",
	[SysActions.REVERSERANGE]: "Reverse Range",
	[SysActions.REVERSESTATUS]: "Rollback Status",

	[SysActions.REWIND]: "Rewind",

	[SysActions.ROLLOVER]: "Rollover",
	[SysActions.RUN]: "Run",
	[SysActions.RUNALL]: "Run All",
	[SysActions.RUNSCHEDULE]: "Run on Schedule",

	[SysActions.SAVE]: "Save",
	[SysActions.SAVEALL]: "Save All",
	[SysActions.SAVEANDCLOSE]: "Save & Close",
	[SysActions.SAVEANDNEW]: "Save & New",

	[SysActions.SCORE]: "Score",
	[SysActions.SEARCH]: "Search",

	[SysActions.SECURITYSTATUS]: "Security Status",

	[SysActions.SEND]: "Send",
	[SysActions.SETFEESPLIT]: "Set Split",
	[SysActions.SETTLE]: "Settle",

	[SysActions.SETUPFEED]: "Setup Feed",

	[SysActions.SKIP]: "Skip",
	[SysActions.SPLIT]: "Split",

	[SysActions.STARTFIX]: "Start FIX",

	[SysActions.STARTTRADE]: "Start Trading",

	[SysActions.STATUS]: "Status",
	[SysActions.STATUSORDERS]: "Status of Orders",

	[SysActions.STOPFIX]: "Stop FIX",

	[SysActions.SUBMIT]: "Submit",
	[SysActions.SUSPEND]: "Suspend",

	[SysActions.TERMINATE]: "Terminate",

	[SysActions.TRADECAPTURE]: "Capture Trades",

	[SysActions.UNAPPROVE]: "Unapprove",
	[SysActions.UNDEPRECIATE]: "Reverse Depreciation",
	[SysActions.UNDEPRECIATEALL]: "Reverse All Depreciation",
	[SysActions.UNDISCARD]: "Undiscard",
	[SysActions.UNFINALIZE]: "Unfinalize",
	[SysActions.UNLOCK]: "Unlock",
	[SysActions.UNPROCESS]: "Unprocess",
	[SysActions.UNSETTLE]: "Unsettle",

	[SysActions.UPDATE]: "Edit",

	[SysActions.VALIDATEALL]: "Validate All",

	[SysActions.VALIDATEDOWNLOAD]: "Validate Download",

	[SysActions.VALUATIONDO]: "Do Valuation",
	[SysActions.VALUATIONDOALL]: "Do Valuation for All",
	[SysActions.VALUATIONUNDO]: "Undo Valuation",
	[SysActions.VALUATIONUNDOALL]: "Undo Valuation for All",

	[SysActions.VAULTINOPS]: "Vault into Ops",
	[SysActions.VAULTINSALES]: "Vault into Sales",

	[SysActions.VERIFY]: "Verify",

	[SysActions.VIEW]: "View",

	[SysActions.VIEW_CMDSTART]: "VIEW_CMDSTART",
	[SysActions.VIEW_CMDEND]: "VIEW_CMDEND",	
	[SysActions.VIEW_FETCHDATA]: "VIEW_FETCHDATA",
	[SysActions.VIEW_RECEIVEDATA]: "VIEW_RECEIVEDATA",

	[SysActions.VIEWHISTORY]: "View History",
	[SysActions.VIEWLEDGER]: "View Ledger",
	[SysActions.VIEWVALUATION]: "View Valuation",
};

