import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { EnumAllOptionValue, NullEntityId, tenYearsAgo, threeYearsAgo, today } from 'app-model/ModelConstants';
import { IFluxAction } from 'app-model/IFluxAction';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxPxWorkflowHistorySubListDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FundContributionBatchList: {
		...fluxListDefaults,
		actionNamespace: 'FundContributionBatchList',
		entityName: 'FundContributionBatch',
		fetchUriTemplate: 'FundContributionBatch/GetContributionBatches(StartDate=${startDate},EndDate=${endDate},FundId=${fundId},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'FundContributionBatch/ExportContributionBatches',
		orderBy: 'BatchDate desc, Fund/InstrumentCode asc',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName,Currency)',
		],
		searchFields: ['Notes'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: EnumAllOptionValue,
		},
	},

	FundContributionBatchDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FundContributionBatchDocumentSubList',
		fetchUriTemplate: 'FundContributionBatch(${subListParentId})/Documents',
		exportUriTemplate: 'FundContributionBatch(${subListParentId})/Documents(${entityId})/Export',
	},

	FundContributionBatchWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FundContributionBatchWorkflowHistorySubList',
		fetchUriTemplate: 'FundContributionBatch(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'FundContributionBatch(${subListParentId})/WorkflowHistory/Export',
	},

	FundContributionList: {
		...fluxListDefaults,
		actionNamespace: 'FundContributionList',
		entityName: 'FundContribution',
		fetchUriTemplate: 'FundContribution/GetFundContributions(StartDate=${startDate},EndDate=${endDate},FundId=${fundId},Searchtext=${searchText})',
		exportUriTemplate: 'FundContribution/ExportFundContributions',
		printUriTemplate: 'FundContribution/PRINT()',
		orderBy: 'Batch/BatchDate desc, Batch/Fund/InstrumentCode asc, Subscriber/Partner/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Batch($select=BatchDate;$expand=Fund($select=InstrumentCode,InstrumentName))',
			'Subscriber($select=SubscriberCode;'
				+ '$expand=SponsorAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'SelfAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'Partner($select=PartnerNo,PartnerLabel),'
				+ 'Fund($select=InstrumentCode,InstrumentName,Currency))',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
		},
	},
	FundContributionSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundContributionSubList',
		entityName: 'FundContribution',
		fetchUriTemplate: 'FundContributionBatch(${subListParentId})/Contributions',
		exportUriTemplate: 'FundContributionBatch(${subListParentId})/ExportContributionForBatch',
		printUriTemplate: 'FundContribution/PRINT()',
		orderBy: 'Subscriber/Partner/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Subscriber($select=SubscriberCode;'
				+ '$expand=SponsorAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'SelfAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'Partner($select=PartnerNo,PartnerLabel),'
				+ 'Fund($select=InstrumentCode,InstrumentName,Currency))',
		],
		searchFields: [
			'Subscriber/SubscriberCode', 
			'Subscriber/Partner/PartnerNo', 'Subscriber/Partner/PartnerLabel', 
			'Subscriber/SelfAcct/InternalAcctNo', 'Subscriber/SelfAcct/AccountTitle',
			'Subscriber/SponsorAcct/InternalAcctNo', 'Subscriber/SponsorAcct/AccountTitle',
		],
		filterValues: {
			searchText: '',
			subListParentId: -1
		},
	},

	FundContributionDistributionList: {
		...fluxListDefaults,
		actionNamespace: 'FundContributionDistributionList',
		entityName: 'FundContributionDistribution',
		fetchUriTemplate: 'FundContributionDistribution/GetFundContributionDistributions(StartDate=${startDate},EndDate=${endDate},FundId=${fundId},Searchtext=${searchText})',
		exportUriTemplate: 'FundContributionDistribution/ExportFundContributionDistributions',
		printUriTemplate: 'FundContributionDistribution/PRINT()',
		orderBy: 'Batch/BatchDate desc, Batch/Fund/InstrumentCode asc, Profile/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Batch($select=BatchDate;$expand=Fund($select=InstrumentCode,InstrumentName))',
			'Profile($select=DefDistribution;$expand=Customer($select=PartnerNo,PartnerLabel))',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},
	FundContributionDistributionSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundContributionDistributionSubList',
		entityName: 'FundContributionDistribution',
		fetchUriTemplate: 'FundContributionBatch(${subListParentId})/Distributions',
		exportUriTemplate: 'FundContributionBatch(${subListParentId})/ExportContributionDistributionForBatch',
		printUriTemplate: 'FundContributionDistribution/PRINT()',
		orderBy: 'Profile/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Profile($select=DefDistribution;$expand=Customer($select=PartnerNo,PartnerLabel))',
		],
		searchFields: ['Profile/Customer/PartnerNo', 'Profile/Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FundContributionBatchView: {
		...fluxViewDefaults,
		actionNamespace: 'FundContributionBatchView',
		entityName: 'FundContributionBatch',
		fetchUriTemplate: 'FundContributionBatch',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName,Currency)',
				
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
