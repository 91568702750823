import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { NullEntityId, oneYearAgo, tenYearsAgo, today } from 'app-model/ModelConstants';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxPxWorkflowHistorySubListDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	MyCashDisburseList: {
		...fluxListDefaults,
		actionNamespace: 'MyCashDisburseList',
		entityName: 'FACashDisburse',
		cmdUriTemplate: 'Me/CashDisburse',
		fetchUriTemplate: 'Me/CashDisburse',
		exportUriTemplate: 'Me/CashDisburse/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			assignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyCashDisburseDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyCashDisburseDocumentSubList',
		fetchUriTemplate: 'FACashDisburse(${subListParentId})/Documents',
		exportUriTemplate: 'FACashDisburse(${subListParentId})/Documents(${entityId})/Export',
	},

	MyCashDisburseWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyCashDisburseWorkflowHistorySubList',
		fetchUriTemplate: 'FACashDisburse(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'FACashDisburse(${subListParentId})/WorkflowHistory/Export',
	},

	MyExpenseAdvanceList: {
		...fluxListDefaults,
		actionNamespace: 'MyExpenseAdvanceList',
		entityName: 'FAExpenseAdvance',
		cmdUriTemplate: 'Me/ExpenseAdvance',
		fetchUriTemplate: `Me/ExpenseAdvance`,
		exportUriTemplate: `Me/ExpenseAdvance/Export`,
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			assignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	MyExpenseAdvancePick: {
		...fluxPickDefaults,
		actionNamespace: 'MyExpenseAdvancePick',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: `Me/ExpenseAdvance`,
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	MyExpenseAdvanceDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyExpenseAdvanceDocumentSubList',
		fetchUriTemplate: 'FAExpenseAdvance(${subListParentId})/Documents',
		exportUriTemplate: 'FAExpenseAdvance(${subListParentId})/Documents(${entityId})/Export',
	},

	MyExpenseAdvanceWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyExpenseAdvanceWorkflowHistorySubList',
		fetchUriTemplate: 'FAExpenseAdvance(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'FAExpenseAdvance(${subListParentId})/WorkflowHistory/Export',
	},

	MyExpenseRetireList: {
		...fluxListDefaults,
		actionNamespace: 'MyExpenseRetireList',
        entityName: 'FAExpenseRetire',
		cmdUriTemplate: 'Me/ExpenseRetire',
		fetchUriTemplate: `Me/ExpenseRetire`,
		exportUriTemplate: `Me/ExpenseRetire/Export`,
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			assignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyExpenseRetireDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyExpenseRetireDocumentSubList',
		fetchUriTemplate: 'FAExpenseRetire(${subListParentId})/Documents',
		exportUriTemplate: 'FAExpenseRetire(${subListParentId})/Documents(${entityId})/Export',
	},

	MyExpenseRetireWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyExpenseRetireWorkflowHistorySubList',
		fetchUriTemplate: 'FAExpenseRetire(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'FAExpenseRetire(${subListParentId})/WorkflowHistory/Export',
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	MyCashDisburseView: {
		...fluxViewDefaults,
		actionNamespace: 'MyCashDisburseView',
		entityName: 'FACashDisburse',
		fetchUriComposited: true,
		fetchUriTemplate: 'Me/CashDisburse(${id})',
		//saveUriTemplate: 'Me/CashDisburse',
	},

	MyExpenseAdvanceView: {
		...fluxViewDefaults,
		actionNamespace: 'MyExpenseAdvanceView',
		entityName: 'FAExpenseAdvance',
		fetchUriComposited: true,
		fetchUriTemplate: 'Me/ExpenseAdvance(${id})',
		saveUriTemplate: 'Me/ExpenseAdvance',
	},

	MyExpenseRetireView: {
		...fluxViewDefaults,
		actionNamespace: 'MyExpenseRetireView',
		entityName: 'FAExpenseRetire',
		fetchUriComposited: true,
		fetchUriTemplate: 'Me/ExpenseRetire(${id})',
		saveUriTemplate: 'Me/ExpenseRetire',
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
