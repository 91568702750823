import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, tenYearsAgo, threeYearsAgo, today } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FundLoanList: {
		...fluxListDefaults,
		actionNamespace: 'FundLoanList',
		entityName: 'FundLoan',
		fetchUriTemplate: 'FundLoan/GetFundLoanForRange(StartDate=${startDate},EndDate=${endDate},FundId=${fundId},LoanTypeId=${loanTypeId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'FundLoan/ExportFundLoanForRange',
		printUriTemplate: 'FundLoan/PRINT',
		orderBy: 'RequestDate desc',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName)',
			'Subscriber($select=SubscriberCode,ClosureType;$expand=Partner($select=PartnerNo,PartnerLabel))',
			'LoanType($select=TypeCode,TypeDesc,InterestRateDays,InterestTypeId)'
		],
		searchFields: ['LoanNo', 'Refinance/LoanNo', 'Subscriber/SubscriberCode', 'Subscriber/Partner/PartnerNo', 'Subscriber/Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			loanTypeId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	FundLoanPick: {
		...fluxPickDefaults,
		actionNamespace: 'FundLoanPick',
		entityName: 'FundLoan',
		fetchUriTemplate: 'FundLoan',
		orderBy: 'RequestDate desc',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName)',
			'Subscriber($select=SubscriberCode;$expand=Partner($select=PartnerNo,PartnerLabel))',
			'LoanType($select=TypeCode,TypeDesc,InterestRateDays,InterestTypeId)'
		],
		searchFields: ['LoanNo', 'Refinance/LoanNo', 'Subscriber/SubscriberCode', 'Subscriber/Partner/PartnerNo', 'Subscriber/Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
		},
	},

	FundLoanDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FundLoanDocumentSubList',
		fetchUriTemplate: 'FundLoan(${subListParentId})/Documents',
		exportUriTemplate: 'FundLoan(${subListParentId})/Documents(${entityId})/Export',
	},

	FundLoanRepayBatchList: {
		...fluxListDefaults,
		actionNamespace: 'FundLoanRepayBatchList',
		entityName: 'FundLoanRepayBatch',
		fetchUriTemplate: 'FundLoanRepayBatch/GetLoanRepayBatches(StartDate=${startDate},EndDate=${endDate},FundId=${fundId},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'FundLoanRepayBatch/ExportLoanRepayBatches',
		orderBy: 'RepayYear desc, RepayMonth desc, Fund/InstrumentCode asc',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName,Currency)',
		],
		searchFields: ['Notes'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: EnumAllOptionValue,
		},
	},

	FundLoanRepayBatchDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FundLoanRepayBatchDocumentSubList',
		fetchUriTemplate: 'FundLoanRepayBatch(${subListParentId})/Documents',
		exportUriTemplate: 'FundLoanRepayBatch(${subListParentId})/Documents(${entityId})/Export',
	},

	FundLoanRepayBatchWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FundLoanRepayBatchWorkflowHistorySubList',
		fetchUriTemplate: 'FundLoanRepayBatch(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'FundLoanRepayBatch(${subListParentId})/WorkflowHistory/Export',
	},

	FundLoanRepayList: {
		...fluxListDefaults,
		actionNamespace: 'FundLoanRepayList',
		entityName: 'FundLoanRepay',
		fetchUriTemplate: 'FundLoanRepay/GetFundLoanRepays(StartDate=${startDate},EndDate=${endDate},FundId=${fundId},ClosureType=${enumParamClosureType},Searchtext=${searchText})',
		exportUriTemplate: 'FundLoanRepay/ExportFundLoanRepays',
		printUriTemplate: 'FundLoanRepay/PRINT',
		orderBy: 'Batch/RepayYear desc, Batch/RepayMonth desc, Subscriber/Partner/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Batch($select=RepayYear,RepayMonth,PreparedDate;$expand=Fund($select=InstrumentCode,InstrumentName))',
			'Subscriber($select=SubscriberCode,ClosureType;'
				+ '$expand=SponsorAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'SelfAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'Partner($select=PartnerNo,PartnerLabel),'
				+ 'Fund($select=InstrumentCode,InstrumentName,Currency))',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			enumParamClosureType: EnumAllOptionValue,
		},
	},
	FundLoanRepaySubList: {
		...fluxListDefaults,
		actionNamespace: 'FundLoanRepaySubList',
		entityName: 'FundLoanRepay',
		fetchUriTemplate: 'FundLoanRepayBatch(${subListParentId})/Repayments',
		exportUriTemplate: 'FundLoanRepayBatch(${subListParentId})/ExportLoanRepayForBatch',
		printUriTemplate: 'FundLoanRepay/PRINT',
		orderBy: 'Subscriber/Partner/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Subscriber($select=SubscriberCode,ClosureType;'
				+ '$expand=SponsorAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'SelfAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'Partner($select=PartnerNo,PartnerLabel),'
				+ 'Fund($select=InstrumentCode,InstrumentName,Currency))',
		],
		searchFields: [
			'Loan/LoanNo',
			'Subscriber/SubscriberCode', 
			'Subscriber/Partner/PartnerNo', 'Subscriber/Partner/PartnerLabel', 
			'Subscriber/SelfAcct/InternalAcctNo', 'Subscriber/SelfAcct/AccountTitle',
			'Subscriber/SponsorAcct/InternalAcctNo', 'Subscriber/SponsorAcct/AccountTitle',
		],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			fundId: NullEntityId,
			enumParamClosureType: EnumAllOptionValue,
		},
	},

	FundLoanRepayDistributionList: {
		...fluxListDefaults,
		actionNamespace: 'FundLoanRepayDistributionList',
		entityName: 'FundLoanRepayDistribution',
		fetchUriTemplate: 'FundLoanRepayDistribution/GetFundLoanRepayDistributions(StartDate=${startDate},EndDate=${endDate},FundId=${fundId},ClosureType=${enumParamClosureType},Searchtext=${searchText})',
		exportUriTemplate: 'FundLoanRepayDistribution/ExportFundLoanRepayDistributions',
		printUriTemplate: 'FundLoanRepayDistribution/PRINT',
		orderBy: 'Batch/RepayYear desc, Batch/RepayMonth desc, Batch/Fund/InstrumentCode asc, Profile/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Batch($select=RepayYear,RepayMonth,PreparedDate;$expand=Fund($select=InstrumentCode,InstrumentName))',
			'Profile($select=DefDistribution;$expand=Customer($select=PartnerNo,PartnerLabel))',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			enumParamClosureType: EnumAllOptionValue,
		},
	},
	FundLoanRepayDistributionSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundLoanRepayDistributionSubList',
		entityName: 'FundLoanRepayDistribution',
		fetchUriTemplate: 'FundLoanRepayBatch(${subListParentId})/Distributions',
		exportUriTemplate: 'FundLoanRepayBatch(${subListParentId})/ExportLoanDistributionForBatch',
		printUriTemplate: 'FundLoanRepayDistribution/PRINT',
		orderBy: 'Profile/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Profile($select=DefDistribution;$expand=Customer($select=PartnerNo,PartnerLabel))',
		],
		searchFields: ['Profile/Customer/PartnerNo', 'Profile/Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			enumParamClosureType: EnumAllOptionValue,
		},
	},

	FundLoanTypeList: {
		...fluxListDefaults,
		actionNamespace: 'FundLoanTypeList',
		entityName: 'FundLoanType',
		fetchUriTemplate: 'Fund/GetFundLoanTypeList()',
		orderBy: 'typecode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['typecode', 'typedesc'],
		filterValues: {
			searchText: '',
			interestTypeId: EnumAllOptionValue,
		},
	},
	FundLoanTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'FundLoanTypePick',
		entityName: 'FundLoanType',
		fetchUriTemplate: 'Fund/GetFundLoanTypeList()',
		orderBy: 'typecode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['typecode', 'typedesc'],
		filterValues: {
			searchText: '',
		},
	},

	FundLoanRepayPlanSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundLoanRepayPlanSubList',
		entityName: 'TermLoanRepayPlan',
		fetchUriTemplate: 'FundLoan(${subListParentId})/GetLoanRepayPlanList()',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	FundLoanSyndicateSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundLoanSyndicateSubList',
		entityName: 'TermLoanSyndicate',
		fetchUriTemplate: 'FundLoan(${subListParentId})/GetLoanSyndicateList()',
		orderBy: 'IsPrimary desc',
		selectFields: [],
		expandProps: [
			'SynCompany($select=CoShortCode,CoName)',
			'SynPartner($select=PartnerNo,PartnerLabel)'
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FundLoanRepayBatchView: {
		...fluxViewDefaults,
		actionNamespace: 'FundLoanRepayBatchView',
		entityName: 'FundLoanRepayBatch',
		fetchUriTemplate: 'FundLoanRepayBatch',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName,Currency)',
				
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	FundLoanTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'FundLoanTypeView',
		entityName: 'FundLoanType',
		fetchUriComposited: true,
		fetchUriTemplate: 'Fund/GetFundLoanTypeById(Id=${id})',
		selectFields: [],
		expandProps: [
			'TypeFees($expand=FeeGlAccts)',
			'TypeGlAccts($expand=OrgCompany($select=CoShortCode,CoName)'
			+ ',InterestChargeAcct($select=AcctNo,AcctTitle),InterestSuspenseAcct($select=AcctNo,AcctTitle)'
			+ ',WriteOffAcct($select=AcctNo,AcctTitle),ProvisionAcct($select=AcctNo,AcctTitle)'
			+ ',WitholdingTaxAcct($select=AcctNo,AcctTitle))',
			'TypeSyndicates($expand=SynCompany($select=CoShortCode,CoName),SynPartner($select=PartnerNo,PartnerLabel))',
		],
	},

	FundLoanView: {
		...fluxViewDefaults,
		actionNamespace: 'FundLoanView',
		entityName: 'FundLoan',
		fetchUriTemplate: 'FundLoan',
		printUriTemplate: 'FundLoan/PRINT',
		// selectFields: [
		// 	'Id,InstrumentTypeId,BillingCompanyId,CounterPartyId,CounterpartyName,TreasuryProfileId,StartDate,Tenure,ExpectedMaturity,Description,FaceValue',
		// 	'InterestOnBalance,DelayedDrawDown,AutoRepay,PrematurePenaltyAmount,PrematurePenaltyRate,DefaultPenaltyAmount,DefaultPenaltyRate,ClosureType,ClosureDate', 
		// 	'Notes' //by the time we change to workflow
		// ],
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName)',
			'Subscriber($select=SubscriberCode;$expand=Partner($select=PartnerNo,PartnerLabel))',
			'LoanType($select=TypeCode,TypeDesc,InterestRateDays,InterestTypeId)',
		],
	},

	FundLoanRepayPlanView: {
		...fluxViewDefaults,
		actionNamespace: 'FundLoanRepayPlanView',
		entityName: 'TermLoanRepayPlan',
		fetchUriComposited: true,
		fetchUriTemplate: 'FundLoan/GetLoanRepayPlan(Id=${id})',
		exportUriTemplate: 'FundLoan(${entityId})/ExportLoanRepayPlanSchedule',
		printUriTemplate: 'FundLoan/PrintLoanRepayPlanSchedule(Id=${entityId})',
		selectFields: [],
		expandProps: [
			'PlanItems',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
