import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, threeYearsAgo, today } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	TermInvestList: {
		...fluxListDefaults,
		actionNamespace: 'TermInvestList',
		entityName: 'TermInvest',
		fetchUriTemplate: 'TermInvest',
		exportUriTemplate: 'TermInvest/Export',
		printUriTemplate: 'TermInvest/PRINT',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			termTypeId: NullEntityId,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	TermInvestPick: {
		...fluxPickDefaults,
		actionNamespace: 'TermInvestPick',
		entityName: 'TermInvest',
		fetchUriTemplate: 'TermInvest',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	TermInvestDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'TermInvestDocumentSubList',
		fetchUriTemplate: 'TermInvest(${subListParentId})/Documents',
		exportUriTemplate: 'TermInvest(${subListParentId})/Documents(${entityId})/Export',
	},

	TermInvestWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'TermInvestWorkflowHistorySubList',
		fetchUriTemplate: 'TermInvest(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'TermInvest(${subListParentId})/WorkflowHistory/Export',
	},

	TermInvestAccrualSubList: {
		...fluxListDefaults,
		actionNamespace: 'TermInvestAccrualSubList',
		entityName: 'TermInvestAccrual',
		fetchUriTemplate: 'TermInvest(${subListParentId})/Accruals',
		exportUriTemplate: 'TermInvest(${subListParentId})/Accruals/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			subListParentId: -1,
		},
	},

	TermInvestPaymentList: {
		...fluxListDefaults,
		actionNamespace: 'TermInvestPaymentList',
		entityName: 'TermInvestPayment',
		fetchUriTemplate: 'TermInvestPayment',
		exportUriTemplate: 'TermInvestPayment/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},
	TermInvestPaymentSubList: {
		...fluxListDefaults,
		actionNamespace: 'TermInvestPaymentSubList',
		entityName: 'TermInvestPayment',
		fetchUriTemplate: 'TermInvest(${subListParentId})/Payments',
		exportUriTemplate: 'TermInvest(${subListParentId})/Payments/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	TermInvestRateSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TermInvestRateSubList',
		entityName: 'TermInvestRate',
		fetchUriTemplate: 'TermInvest(${subListParentId})/Rates',
		exportUriTemplate: 'TermInvest(${subListParentId})/Rates/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	TermInvestTypeList: {
		...fluxListDefaults,
		actionNamespace: 'TermInvestTypeList',
		entityName: 'TermInvestType',
		fetchUriTemplate: 'TermInvestType',
		exportUriTemplate: 'TermInvestType/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			counterPartyType: EnumAllOptionValue,
			interestTypeId: EnumAllOptionValue,
		},
	},
	TermInvestTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'TermInvestTypePick',
		entityName: 'TermInvestType',
		fetchUriTemplate: 'TermInvestType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	TermInvestView: {
		...fluxViewDefaults,
		actionNamespace: 'TermInvestView',
		entityName: 'TermInvest',
		fetchUriTemplate: 'TermInvest',
		printUriTemplate: 'TermInvest/PRINT',
		queryUseRestEndpoint: true,
	},

	TermInvestTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'TermInvestTypeView',
		entityName: 'TermInvestType',
		fetchUriTemplate: 'TermInvestType',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
