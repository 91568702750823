import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	MyLeaveList: {
		...fluxListDefaults,
		actionNamespace: 'MyLeaveList',
		entityName: 'HRLeave',
		fetchUriTemplate: 'Me/Leave',
		exportUriTemplate: 'Me/Leave/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			leaveType: EnumAllOptionValue,
			assignment: EnumAllOptionValue,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyLeaveDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyLeaveDocumentSubList',
		fetchUriTemplate: 'HRLeave(${subListParentId})/Documents',
		exportUriTemplate: 'HRLeave(${subListParentId})/Documents(${entityId})/Export',
	},

	MyLeaveWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyLeaveWorkflowHistorySubList',
		fetchUriTemplate: 'HRLeave(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'HRLeave(${subListParentId})/WorkflowHistory/Export',
	},

	MyLeaveRotaList: {
		...fluxListDefaults,
		actionNamespace: 'MyLeaveRotaList',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'Me/LeaveRota',
		exportUriTemplate: 'Me/LeaveRota/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			assignment: EnumAllOptionValue,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyLeaveRotaDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyLeaveRotaDocumentSubList',
		fetchUriTemplate: 'HRLeaveRota(${subListParentId})/Documents',
		exportUriTemplate: 'HRLeaveRota(${subListParentId})/Documents(${entityId})/Export',
	},

	MyLeaveRotaWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyLeaveRotaWorkflowHistorySubList',
		fetchUriTemplate: 'HRLeaveRota(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'HRLeaveRota(${subListParentId})/WorkflowHistory/Export',
	},

	MyLeaveYearQuotList: {
		...fluxListDefaults,
		actionNamespace: 'MyLeaveYearQuotList',
		entityName: 'HRLeaveYearQuota',
		fetchUriTemplate: 'Me/LeaveYear(${leaveYearId})/LeaveQuotas',
		exportUriTemplate: 'Me/LeaveYear(${leaveYearId})/LeaveQuotas/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			leaveYearId: NullEntityId,
			leaveType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},

	MyUnitLeaveList: {
		...fluxListDefaults,
		actionNamespace: 'MyUnitLeaveList',
		entityName: 'HRLeave',
		fetchUriTemplate: 'Me/Unit/Leave',
		exportUriTemplate: 'Me/Unit/Leave/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			leaveType: EnumAllOptionValue,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyUnitLeaveRotaList: {
		...fluxListDefaults,
		actionNamespace: 'MyUnitLeaveRotaList',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'Me/Unit/LeaveRota',
		exportUriTemplate: 'Me/Unit/LeaveRota/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	MyLeaveView: {
		...fluxViewDefaults,
		actionNamespace: 'MyLeaveView',
		entityName: 'HRLeave',
		fetchUriTemplate: 'Me/Leave',
		queryUseRestEndpoint: true,
	},

	MyLeaveRotaView: {
		...fluxViewDefaults,
		actionNamespace: 'MyLeaveRotaView',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'Me/LeaveRota',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
