import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { JournalClosure, PartnerState } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, today, oneMonthAgo, threeMonthsAgo, oneYearAgo, oneYearFuture } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PxWorkflowStateFilter } from 'app-model/enums';

const stLists : IListComponentControlList = {
	FAGlAcctActivityList: {
		...fluxListDefaults,
		actionNamespace: 'FAGlAcctActivityList',
		entityName: 'FAGlAcctActivity',
		fetchUriTemplate: 'orgcompany(${companyId})/GetGlAcctActivityForRange',
		exportUriTemplate: 'orgcompany(${companyId})/ExportGlAcctActivityForRange',
		keyField: 'AccountId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAcctCategory: EnumAllOptionValue,
			acctTypeId: null,
		},
	},

	FAGlAcctBalanceList: {
		...fluxListDefaults,
		actionNamespace: 'FAGlAcctBalanceList',
		entityName: 'FAGlAcctBalance',
		fetchUriTemplate: 'orgcompany(${companyId})/GlAcctBalances',
		exportUriTemplate: 'orgcompany(${companyId})/GlAcctBalances/Export',
		keyField: 'AccountId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			atDate: toIsoDateString(today),
			enumParamAcctCategory: EnumAllOptionValue,
			acctTypeId: null,
		},
	},

	FAGlAcctList: {
		...fluxListDefaults,
		actionNamespace: 'FAGlAcctList',
		entityName: 'FAGlAcct',
		fetchUriTemplate: 'OrgCompany(${companyId})/GlAccts',
		exportUriTemplate: 'OrgCompany(${companyId})/GlAccts/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			glType: EnumAllOptionValue,
			acctType: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	FAGlAcctPick: {
		...fluxPickDefaults,
		actionNamespace: 'FAGlAcctPick',
		entityName: 'FAGlAcct',
		fetchUriTemplate: 'OrgCompany(${companyId})/GlAccts',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	FAGlAcctHistorySubList: {
		...fluxListDefaults,
		actionNamespace: 'FAGlAcctHistorySubList',
		entityName: 'FAGlAcctHistory',
		fetchUriTemplate: 'FAGlAcct(${subListParentId})/GlAcctHistory',
		exportUriTemplate: 'FAGlAcct(${subListParentId})/GlAcctHistory/Export',
		printUriTemplate: 'FAGlAcct(${subListParentId})/GlAcctHistory/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamEntryType: EnumAllOptionValue,
			enumParamEntryMethod: EnumAllOptionValue,
		},
	},

	FAGlRevalueBatchList: {
		...fluxListDefaults,
		actionNamespace: 'FAGlRevalueBatchList',
		entityName: 'FAGlRevalueBatch',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCoGlRevalueBatch(StartDate=${startDate},EndDate=${endDate},ClosureType=${enumParamClosureType})',
		orderBy: 'ValueDate desc',
		selectFields: ['Id', 'BatchNo', 'Currency', 'CurrencyRate', 'ValueDate', 'Description', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Description', 'BatchNo'],
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FAStatAcctBalanceList: {
		...fluxListDefaults,
		actionNamespace: 'FAStatAcctBalanceList',
		entityName: 'FAStatAcctBalance',
		fetchUriTemplate: 'orgcompany(${companyId})/StatAcctBalances',
		exportUriTemplate: 'orgcompany(${companyId})/StatAcctBalances/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			atDate: toIsoDateString(today),
			enumParamMeasureType: EnumAllOptionValue,
		},
	},

	FAStatAcctList: {
		...fluxListDefaults,
		actionNamespace: 'FAStatAcctList',
		entityName: 'FAStatAcct',
		fetchUriTemplate: 'orgcompany(${companyId})/StatAccts',
		exportUriTemplate: 'orgcompany(${companyId})/StatAccts/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			closureType: PartnerState.Active,
		},
	},
	FAStatAcctPick: {
		...fluxPickDefaults,
		actionNamespace: 'FAStatAcctPick',
		entityName: 'FAStatAcct',
		fetchUriTemplate: 'orgcompany(${companyId})/StatAccts',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FAGlAcctView: {
		...fluxViewDefaults,
		actionNamespace: 'FAGlAcctView',
		entityName: 'FAGlAcct',
		fetchUriTemplate: 'FAGlAcct',
		queryUseRestEndpoint: true,
	},

	FAGlRevalueBatchView: {
		...fluxViewDefaults,
		actionNamespace: 'FAGlRevalueBatchView',
		entityName: 'FAGlRevalueBatch',
		fetchUriTemplate: 'FAGlRevalueBatch',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName,CoOrgType)', 
			'GainAcct($select=AcctNo,AcctTitle)', 
			'LossAcct($select=AcctNo,AcctTitle)', 
			'Entries($expand=Acct($select=AcctNo,AcctTitle))'
		],
	},

	FAJrnlBatchLedger: {
		...fluxViewDefaults,
		actionNamespace: 'FAJrnlBatchLedger',
		entityName: 'FAJrnlBatch',
		fetchUriTemplate: 'FAJrnlBatch',
		queryUseRestEndpoint: true,
	},

	FAJrnlGlPageLedger: {
		...fluxViewDefaults,
		actionNamespace: 'FAJrnlGlPageLedger',
		entityName: 'FAJrnlGlPage',
		fetchUriTemplate: 'FAJrnlGlPage',
		queryUseRestEndpoint: true,
	},

	FAStatAcctView: {
		...fluxViewDefaults,
		actionNamespace: 'FAStatAcctView',
		entityName: 'FAStatAcct',
		fetchUriTemplate: 'FAStatAcct',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName,CoOrgType)', 
			'SummaryAcct($select=AcctNo,AcctTitle)'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
