import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { GeneralApprovalClosureSupra } from 'app-model/enums';
import { IFluxAction } from 'app-model/IFluxAction';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	WillList: {
		...fluxListDefaults,
		actionNamespace: 'WillList',
        entityName: 'Will',
		fetchUriTemplate: 'Will',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: GeneralApprovalClosureSupra.ACTIVE,
		},
	},
	WillPick: {
		...fluxPickDefaults,
		actionNamespace: 'WillPick',
        entityName: 'Will',
		fetchUriTemplate: 'Will',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: GeneralApprovalClosureSupra.ACTIVE,
		},
	},

	WillBankAcctSubList: {
		...fluxListDefaults,
		actionNamespace: 'WillBankAcctSubList',
		entityName: 'WillBankAcct',
		fetchUriTemplate: 'Will(${subListParentId})/BankAccts',
		exportUriTemplate: 'Will(${subListParentId})/BankAccts/Export',
		cmdUriTemplate: 'Will(${subListParentId})/BankAccts',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	WillBeneficiarySubList: {
		...fluxListDefaults,
		actionNamespace: 'WillBeneficiarySubList',
		entityName: 'WillBeneficiary',
		fetchUriTemplate: 'Will(${subListParentId})/Beneficiaries',
		exportUriTemplate: 'Will(${subListParentId})/Beneficiaries/Export',
		cmdUriTemplate: 'Will(${subListParentId})/Beneficiaries',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	WillGuardianSubList: {
		...fluxListDefaults,
		actionNamespace: 'WillGuardianSubList',
		entityName: 'WillGuardian',
		fetchUriTemplate: 'Will(${subListParentId})/Guardians',
		exportUriTemplate: 'Will(${subListParentId})/Guardians/Export',
		cmdUriTemplate: 'Will(${subListParentId})/Guardians',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	WillInvestmentAcctSubList: {
		...fluxListDefaults,
		actionNamespace: 'WillInvestmentAcctSubList',
		entityName: 'WillInvestmentAcct',
		fetchUriTemplate: 'Will(${subListParentId})/InvestmentAccts',
		exportUriTemplate: 'Will(${subListParentId})/InvestmentAccts/Export',
		cmdUriTemplate: 'Will(${subListParentId})/InvestmentAccts',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	WillView: {
		...fluxViewDefaults,
		actionNamespace: 'WillView',
		entityName: 'Will',
		fetchUriTemplate: 'Will',
		queryUseRestEndpoint: true,
	},

	WillBankAcctView: {
		...fluxViewDefaults,
		actionNamespace: 'WillBankAcctView',
		entityName: 'WillBankAcct',
		fetchUriTemplate: 'WillBankAcct',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
