import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, tenYearsAgo, threeYearsAgo, today } from 'app-model/ModelConstants';
import { ContractClosure } from 'app-model/enums';
import { PartnerState, TermInstrumentClosure } from 'app-model/enums';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxPartnerBankAcctSubListDefaults, fluxDocumentSubListDefaults, fluxPartnerLocationSubListDefaults, fluxPartnerRelationshipSubListDefaults, fluxPxWorkflowHistorySubListDefaults, fluxPartnerInterestRunSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CmpCustomerRelMgrList: {
		...fluxListDefaults,
		actionNamespace: 'CmpCustomerRelMgrList',
		entityName: 'PartnerRelMgr',
		fetchUriTemplate: 'Customer/RelMgrs',
		exportUriTemplate: 'Customer/RelMgrs/Export',
		cmdUriTemplate: 'Customer/RelMgrs',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			relMgrId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	CustomerDocumentTOList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerDocumentTOList',
		entityName: 'CustomerDocumentTO',
		fetchUriTemplate: 'CustomerDocumentTO/GetKypDocuments',
		exportUriTemplate: 'CustomerDocumentTO/ExportKypDocuments',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			enumParamDocumentClass: EnumAllOptionValue,
			relMgrId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},
	
	CustomerOpeningList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerOpeningList',
		entityName: 'CustomerOpening',
		fetchUriTemplate: 'CustomerOpening',
		exportUriTemplate: 'CustomerOpening/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			typeCode: EnumAllOptionValue,
			assignment: EnumAllOptionValue,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	CustomerInterestRunSubList: {
		...fluxPartnerInterestRunSubListDefaults,
		actionNamespace: 'CustomerInterestRunSubList',
		entityName: 'PartnerInterestRun',
		cmdUriTemplate: 'Customer(${subListParentId})/InterestRuns',
		fetchUriTemplate: 'Customer(${subListParentId})/InterestRuns',
		exportUriTemplate: 'Customer(${subListParentId})/InterestRuns/Export',
	},

	CustomerOpeningBankAcctSubList: {
		...fluxPartnerBankAcctSubListDefaults,
		actionNamespace: 'CustomerOpeningBankAcctSubList',
		cmdUriTemplate: 'CustomerOpening(${subListParentId})/BankAccts',
		fetchUriTemplate: 'CustomerOpening(${subListParentId})/BankAccts',
		exportUriTemplate: 'CustomerOpening(${subListParentId})/BankAccts/Export',
	},

	CustomerOpeningDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'CustomerOpeningDocumentSubList',
		fetchUriTemplate: 'CustomerOpening(${subListParentId})/Documents',
		exportUriTemplate: 'CustomerOpening(${subListParentId})/Documents(${entityId})/Export',
	},

	CustomerOpeningLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'CustomerOpeningLocationSubList',
		fetchUriTemplate: 'CustomerOpening(${subListParentId})/Locations',
		exportUriTemplate: 'CustomerOpening(${subListParentId})/Locations/Export',
	},

	CustomerOpeningRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'CustomerOpeningRelationshipSubList',
		cmdUriTemplate: 'CustomerOpening(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'CustomerOpening(${subListParentId})/RelatedParties',
		exportUriTemplate: 'CustomerOpening(${subListParentId})/RelatedParties/Export',
	},

	CustomerOpeningWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'CustomerOpeningWorkflowHistorySubList',
		fetchUriTemplate: 'CustomerOpening(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'CustomerOpening(${subListParentId})/WorkflowHistory/Export',
	},

	CustomerSubClassList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerSubClassList',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'PartnerSubClass',
		exportUriTemplate: 'PartnerSubClass/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			tranEntityType: 'Griffin.Domain.Customer',
			closureType: PartnerState.Active,
		},
	},
	CustomerSubClassPick: {
		...fluxPickDefaults,
		actionNamespace: 'CustomerSubClassPick',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'PartnerSubClass',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			tranEntityType: 'Griffin.Domain.Customer',
		},
	},

	EQContractNoteSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQContractNoteSubList',
		entityName: 'EQContractNote',
		fetchUriTemplate: 'Customer(${subListParentId})/EQContracts',
		exportUriTemplate: 'Customer(${subListParentId})/EQContracts/Export',
		printUriTemplate: 'EQContractNote/PRINT',
		keyField: 'ContractNoteId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			instrumentId: NullEntityId,
			enumParamTradeType: EnumAllOptionValue,
			enumParamClosureType: ContractClosure.Posted,
		},
	},

	EQOrderSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQOrderSubList',
		entityName: 'EQOrder',
		fetchUriTemplate: 'Customer(${subListParentId})/GetEQOrderForCustomer(StartDate=${startDate},EndDate=${endDate},Channel=${enumParamChannel},OrderType=${enumParamTradeType},InstrId=${instrumentId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportEQOrderForCustomer',
		orderBy: 'OrderDate desc, EQAccount/AccountTitle asc',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName,Currency;$expand=ListExchange($select=UniqueCode,CommonName))',
		],
		searchFields: ['EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrumentId: NullEntityId,
			enumParamChannel: EnumAllOptionValue,
			enumParamTradeType: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	TermInvestSubList: {
		...fluxListDefaults,
		actionNamespace: 'TermInvestSubList',
		entityName: 'TermInvest',
		fetchUriTemplate: 'Customer(${subListParentId})/GetTermInvestForCustomer(StartDate=${startDate},EndDate=${endDate},TermTypeId=${termTypeId},ClosureType=${enumParamClosureType},SearchText=${searchText})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportTermInvestForCustomer',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'InvestType($select=TypeCode,TypeDesc,InterestRateDays)'
		],
		searchFields: ['Partner/PartnerNo', 'Partner/PartnerLabel', 'InvestType/TypeCode', 'InvestType/TypeDesc'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			termTypeId: NullEntityId,
			enumParamClosureType: TermInstrumentClosure.Running,
		},
	},

	TermLoanSubList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanSubList',
		entityName: 'TermLoan',
		fetchUriTemplate: 'Customer(${subListParentId})/GetTermLoanForCustomer(StartDate=${startDate},EndDate=${endDate},TermTypeId=${termTypeId},ClosureType=${enumParamClosureType},SearchText=${searchText})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportTermLoanForCustomer',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'LoanType($select=TypeCode,TypeDesc,InterestRateDays)'
		],
		searchFields: ['Partner/PartnerNo', 'Partner/PartnerLabel', 'LoanType/TypeCode', 'LoanType/TypeDesc'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			termTypeId: NullEntityId,
			enumParamClosureType: TermInstrumentClosure.Running,
		},
	},

	TRTBillOrderSubList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillOrderSubList',
		entityName: 'TRTBillOrder',
		fetchUriTemplate: 'Customer(${subListParentId})/GetTRTBillOrderForCustomer(StartDate=${startDate},EndDate=${endDate},Channel=${enumParamChannel},OrderType=${enumParamOrderType},Currency=${enumParamCurrency},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportTRTBillOrderForCustomer',
		orderBy: 'OrderDate desc, Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
			'TRTBillType($select=TypeCode,Country,Currency)',
		],
		searchFields: ['Customer/PartnerNo', 'Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			enumParamChannel: EnumAllOptionValue,
			enumParamOrderType: EnumAllOptionValue,
			enumParamCurrency: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	TRTBillTranSubList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillTranSubList',
		entityName: 'TRTBillTran',
		fetchUriTemplate: 'Customer(${subListParentId})/GetTRTBillTranForCustomer(StartDate=${startDate},EndDate=${endDate},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'Customer(${subListParentId})/ExportTRTBillTranForCustomer',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'TRTBillType($select=TypeCode,Country,Currency)',
			'TRTBillDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
		searchFields: ['AllotNotes', 'BillTranNo', 'Warehouse/PartnerNo', 'Warehouse/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamClosureType: EnumAllOptionValue,
		},
	},

	VALBondAfsHoldingSubList: {
		...fluxListDefaults,
		sizePage: 100,
		actionNamespace: 'VALBondAfsHoldingSubList',
		entityName: 'VALBondAfsHolding',
		fetchUriTemplate: 'Customer(${subListParentId})/BondAfsHoldings',
		exportUriTemplate: 'Customer(${subListParentId})/BondAfsHoldings/Export',
		printUriTemplate: 'Customer(${subListParentId})/BondAfsHoldings/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},

	VALBondHtmHoldingSubList: {
		...fluxListDefaults,
		sizePage: 100,
		actionNamespace: 'VALBondHtmHoldingSubList',
		entityName: 'VALBondHtmHolding',
		fetchUriTemplate: 'Customer(${subListParentId})/BondHtmHoldings',
		exportUriTemplate: 'Customer(${subListParentId})/BondHtmHoldings/Export',
		printUriTemplate: 'Customer(${subListParentId})/BondHtmHoldings/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},

	VALCustSummarySubList: {
		...fluxListDefaults,
		actionNamespace: 'VALCustSummarySubList',
		entityName: 'VALCustSummary',
		fetchUriTemplate: 'Customer(${subListParentId})/ValuationHistory',
		exportUriTemplate: 'Customer(${subListParentId})/ValuationHistory/Export',
		printUriTemplate: 'Customer(${subListParentId})/ValuationHistory/Print',
		keyField: 'CustomerId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	VALHoldingAggregateSubList: {
		...fluxListDefaults,
		sizePage: 100,
		actionNamespace: 'VALHoldingAggregateSubList',
		entityName: 'VALHoldingAggregate',
		fetchUriTemplate: 'Customer(${subListParentId})/StockHoldings',
		exportUriTemplate: 'Customer(${subListParentId})/StockHoldings/Export',
		printUriTemplate: 'Customer(${subListParentId})/StockHoldings/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	CustomerOpeningView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerOpeningView',
		entityName: 'CustomerOpening',
		fetchUriTemplate: 'CustomerOpening',
		queryUseRestEndpoint: true,
	},

	CustomerValuation: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerValuation',
		entityName: 'Customer',
		fetchUriTemplate: 'Customer',
		printUriTemplate: 'Customer(${entityId})/ValuationSummary/Print',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName)',
			'PartnerSubClass($select=SubClassCode)',
			'BenchmarkInstrument($select=InstrumentCode,InstrumentName)',
			'MasterRecord($expand=PrimaryAddress,OrgLocation($select=LocationCode,Description))',
			'PartnerGlAccts($expand=OrgCompany($select=CoShortCode,CoName),FAGlAcct($select=AcctNo,AcctTitle,CashAcctNo))',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
