import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';

import { fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	NFBulkTypeList: {
		...fluxListDefaults,
		actionNamespace: 'NFBulkTypeList',
		entityName: 'NFBulkType',
		fetchUriTemplate: 'NFBulkType',
		exportUriTemplate: 'NFBulkType/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			deliveryMethod: EnumAllOptionValue,
		},
	},
	
	NFQueuedItemList: {
		...fluxListDefaults,
		actionNamespace: 'NFQueuedItemList',
		entityName: 'NFQueuedItem',
		fetchUriTemplate: 'NFQueuedItem',
		exportUriTemplate: 'NFQueuedItem/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			notifyTypeId: NullEntityId,
			deliveryMethod: EnumAllOptionValue,
		},
	},
	
	NFTemplateList: {
		...fluxListDefaults,
		actionNamespace: 'NFTemplateList',
		entityName: 'NFTemplate',
		fetchUriTemplate: 'NFTemplate',
		exportUriTemplate: 'NFTemplate/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			notifyTypeId: NullEntityId,
			deliveryMethod: EnumAllOptionValue,
		},
	},
	NFTemplatePick: {
		...fluxPickDefaults,
		actionNamespace: 'NFTemplatePick',
		entityName: 'NFTemplate',
		fetchUriTemplate: 'NFTemplate',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
		filterExpressions: [],
	},
	
	NFTypeDefList: {
		...fluxListDefaults,
		actionNamespace: 'NFTypeDefList',
		entityName: 'NFTypeDef',
		fetchUriTemplate: 'NFTypeDef',
		exportUriTemplate: 'NFTypeDef/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	NFTemplateView: {
		...fluxViewDefaults,
		actionNamespace: 'NFTemplateView',
		entityName: 'NFTemplate',
		fetchUriTemplate: 'NFTemplate',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
