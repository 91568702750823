import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, today, DefNgNseExchangeId, tenYearsAgo, oneYearAgo, threeYearsAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PartnerState } from 'app-model/enums';

const stLists : IListComponentControlList = {
	CmpTradeFeeList: {
		...fluxListDefaults,
		actionNamespace: 'CmpTradeFeeList',
		entityName: 'CmpTradeFee',
		fetchUriTemplate: 'CmpTradeFee/Prevailing',
		exportUriTemplate: 'CmpTradeFee/Prevailing/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			atDate: toIsoDateString(today),
			enumParamInstrType: EnumAllOptionValue,
		},
	},

	EQInstrumentTechnicalInfoTOList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentTechnicalInfoTOList',
		entityName: 'EQInstrumentTechnicalInfoTO',
		keyField: 'InstrumentId',
		fetchUriTemplate: 'RefExchangeDef(${exchangeId})/TechnicalInfo',
		exportUriTemplate: 'RefExchangeDef(${exchangeId})/TechnicalInfo/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			atDate: toIsoDateString(today),
			enumParamInstrType: EnumAllOptionValue,
		},
	},

	EQMarketCapitalList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketCapitalList',
		entityName: 'EQMarketCapital',
		fetchUriTemplate: 'EQMarketCapital',
		exportUriTemplate: 'EQMarketCapital/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	EQMarketDealAggrSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketDealAggrSubList',
		entityName: 'EQMarketDealAggr',
		fetchUriTemplate: 'EQMarketInfoSheet(${subListParentId})/MarketDealAggr',
		exportUriTemplate: 'EQMarketInfoSheet(${subListParentId})/MarketDealAggr/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQMarketEquitySubList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketEquitySubList',
		entityName: 'EQMarketEquity',
		fetchUriTemplate: 'EQMarketInfoSheet(${subListParentId})/MarketEquity',
		exportUriTemplate: 'EQMarketInfoSheet(${subListParentId})/MarketEquity/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQMarketInfoSheetList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketInfoSheetList',
		entityName: 'EQMarketInfoSheet',
		fetchUriTemplate: 'EQMarketInfoSheet',
		exportUriTemplate: 'EQMarketInfoSheet/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	NSETerminalPriceSubList: {
		...fluxListDefaults,
		actionNamespace: 'NSETerminalPriceSubList',
		entityName: 'NSETerminalPrice',
		fetchUriTemplate: 'EQMarketInfoSheet(${subListParentId})/TerminalPrice',
		exportUriTemplate: 'EQMarketInfoSheet(${subListParentId})/TerminalPrice/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	ResCompanyList: {
		...fluxListDefaults,
		actionNamespace: 'ResCompanyList',
		entityName: 'ResCompany',
		fetchUriTemplate: 'ResCompany',
		exportUriTemplate: 'ResCompany/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			classCode: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	ResCompanyPick: {
		...fluxPickDefaults,
		actionNamespace: 'ResCompanyPick',
		entityName: 'ResCompany',
		fetchUriTemplate: 'ResCompany',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	ResCompanyCapitalList: {
		...fluxListDefaults,
		actionNamespace: 'ResCompanyCapitalList',
		entityName: 'ResCompanyCapital',
		fetchUriTemplate: 'ResCompanyCapital',
		exportUriTemplate: 'ResCompanyCapital/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	ResCompanyResultList: {
		...fluxListDefaults,
		actionNamespace: 'ResCompanyResultList',
		entityName: 'ResCompanyResult',
		fetchUriTemplate: 'ResCompanyResult',
		exportUriTemplate: 'ResCompanyResult/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			resultClass: EnumAllOptionValue,
			resultType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQInstrumentView: {
		...fluxViewDefaults,
		actionNamespace: 'EQInstrumentView',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		queryUseRestEndpoint: true,
	},

	EQMarketInfoSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'EQMarketInfoSheetView',
		entityName: 'EQMarketInfoSheet',
		fetchUriTemplate: 'EQMarketInfoSheet',
		queryUseRestEndpoint: true,
	},

	ResCompanyView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyView',
		entityName: 'ResCompany',
		fetchUriTemplate: 'ResCompany',
		queryUseRestEndpoint: true,
	},

	ResCompanyCapitalView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyCapitalView',
		entityName: 'ResCompanyCapital',
		fetchUriTemplate: 'ResCompanyCapital',
		queryUseRestEndpoint: true,
	},

	ResCompanyReportView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyReportView',
		entityName: 'ResCompanyReport',
		fetchUriTemplate: 'ResCompanyReport',
		queryUseRestEndpoint: true,
	},

	ResCompanyResultView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyResultView',
		entityName: 'ResCompanyResult',
		fetchUriTemplate: 'ResCompanyResult',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
