import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { EnumAllOptionValue, NullEntityId, today, threeYearsAgo } from 'app-model/ModelConstants';
import { IFluxAction } from 'app-model/IFluxAction';
import { PxWorkflowAssignmentFilter } from 'app-model/enums';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxPxWorkflowHistorySubListDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FundCaseList: {
		...fluxListDefaults,
		actionNamespace: 'FundCaseList',
		entityName: 'PartnerCase',
		fetchUriTemplate: 'PartnerCase/GetFundSubscriberWrkflwCases(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'PartnerCase/ExportFundSubscriberWrkflwCases',
		orderBy: 'LodgeDate desc',
		selectFields: [],
		expandProps: ['PartnerMaster($select=PartnerNo,PartnerLabel)'],
		searchFields: ['PartnerMaster/PartnerNo', 'PartnerMaster/PartnerLabel', 'Subject', 'Notes'],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: EnumAllOptionValue
		},
	},

	FundCaseDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FundCaseDocumentSubList',
		fetchUriTemplate: 'PartnerCase(${subListParentId})/Documents',
		exportUriTemplate: 'PartnerCase(${subListParentId})/Documents(${entityId})/Export',
	},

	FundCaseWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FundCaseWorkflowHistorySubList',
		fetchUriTemplate: 'PartnerCase(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'PartnerCase(${subListParentId})/WorkflowHistory/Export',
	},

	FundSubscriberHoldingList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'FundSubscriberHoldingList',
		entityName: 'VALFundSubscriberHolding',
		fetchUriTemplate: 'Fund/GetAllSubscriberHoldings(AtDate=${atDate},CompanyId=${companyId},SubClassId=${subClassId},ExchangeId=${exchangeId},FundId=${fundId},ClosureType=${enumParamClosureType},SearchText=${searchText})',
		exportUriTemplate: 'Fund/ExportAllSubscriberHoldings',
		orderBy: 'PartnerLabel asc, FundCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			exchangeId: NullEntityId,
			fundId: NullEntityId,
			enumParamClosureType: EnumAllOptionValue,
		},
	},

	FundSubscriberList: {
		...fluxListDefaults,
		actionNamespace: 'FundSubscriberList',
		entityName: 'FundSubscriber',
		fetchUriTemplate: 'FundSubscriber',
		exportUriTemplate: 'FundSubscriber/ExportFundSubscribers', //(FundId=${fundId},ClosureType=${enumParamClosureType})',
		printUriTemplate: 'FundSubscriber/PRINT()',
		orderBy: 'Partner/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'SponsorAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'SelfAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'Partner($select=PartnerNo,PartnerLabel)',
			'Fund($select=InstrumentCode,InstrumentName,Currency)',
		],
		searchFields: [
			'SponsorAcct/InternalAcctNo', 'SponsorAcct/ExchangeAcctNo', 'SponsorAcct/AccountTitle',
			'SelfAcct/InternalAcctNo', 'SelfAcct/ExchangeAcctNo', 'SelfAcct/AccountTitle',
			'Partner/PartnerNo', 'Partner/PartnerLabel',
		],
		filterValues: {
			searchText: '',
			fundId: NullEntityId,
			enumParamClosureType: EnumAllOptionValue,
		},
	},
	FundSubscriberPick: {
		...fluxPickDefaults,
		actionNamespace: 'FundSubscriberPick',
		entityName: 'FundSubscriber',
		fetchUriTemplate: 'FundSubscriber',
		orderBy: 'Partner/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'SponsorAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'SelfAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'Partner($select=PartnerNo,PartnerLabel)',
			'Fund($select=InstrumentCode,InstrumentName,Currency)',
		],
		searchFields: [
			'SponsorAcct/InternalAcctNo', 'SponsorAcct/ExchangeAcctNo', 'SponsorAcct/AccountTitle',
			'SelfAcct/InternalAcctNo', 'SelfAcct/ExchangeAcctNo', 'SelfAcct/AccountTitle',
			'Partner/PartnerNo', 'Partner/PartnerLabel',
		],
		filterValues: {
			searchText: '',
		},
	},

	FundSubscriberDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FundSubscriberDocumentSubList',
		fetchUriTemplate: 'FundSubscriber(${subListParentId})/Documents',
		exportUriTemplate: 'FundSubscriber(${subListParentId})/Documents(${entityId})/Export',
	},

	FundSubscriberSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundSubscriberSubList',
		entityName: 'FundSubscriber',
		fetchUriTemplate: 'Fund(${subListParentId})/FundSubscribers',
		printUriTemplate: 'FundSubscriber/PRINT()',
		orderBy: 'Partner/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'SponsorAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'SelfAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'Partner($select=PartnerNo,PartnerLabel)',
		],
		searchFields: ['EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			fundId: NullEntityId,
			transType: EnumAllOptionValue,
		},
	},

	VALFundLoanList: {
		...fluxListDefaults,
		sizePage: 100,
		actionNamespace: 'VALFundLoanList',
		entityName: 'VALFundLoan',
		fetchUriTemplate: 'FundSubscriber/GetAllLoans(AtDate=${atDate},SubClassId=${subClassId},FundId=${fundId},TypeId=${typeId},SearchText=${searchText})',
		exportUriTemplate: 'FundSubscriber/ExportAllLoans',
		orderBy: 'ExpectedMaturity asc, PartnerLabel asc',
		keyField: 'LoanId',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			subClassId: NullEntityId,
			fundId: NullEntityId,
			typeId: NullEntityId,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FundCaseView: {
		...fluxViewDefaults,
		actionNamespace: 'FundCaseView',
		entityName: 'PartnerCase',
		fetchUriTemplate: 'PartnerCase',
		selectFields: [],
		expandProps: [
			'PartnerMaster($select=PartnerNo,PartnerLabel)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	FundSubscriberView: {
		...fluxViewDefaults,
		actionNamespace: 'FundSubscriberView',
		entityName: 'FundSubscriber',
		fetchUriTemplate: 'FundSubscriber',
		selectFields: [],
		expandProps: [
			'FundTradeSheet($select=TradeDate)',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentType,InstrumentCode,InstrumentName)',
			'WarehouseTradeAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'DealAllotCommission($select=GroupName)',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'Executor($select=OperatorNo,OperatorCode,OperatorLabel)',
			'FundSubscriberFees',
		],
	},

	FundOrderView: {
		...fluxViewDefaults,
		actionNamespace: 'FundOrderView',
		entityName: 'FundOrder',
		fetchUriTemplate: 'FundOrder',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName,Currency;$expand=ListExchange($select=UniqueCode,CommonName))',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'Executor($select=OperatorNo,OperatorCode,OperatorLabel)',
			'OrderCommission($select=GroupName)',
			'FundOrderFees'
		],
	},

	FundTradeSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'FundTradeSheetView',
		entityName: 'FundTradeSheet',
		fetchUriTemplate: 'FundTradeSheet',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
