import { FluentIconNames } from "app-model/FluentIconNames";

import { SysActions } from "./SysActionDefn";

export const SysActionFluentIcons = {
	//[SysActions.ACTIVATE]: "cil-hand-point-up",
	[SysActions.ACTIVATE]: FluentIconNames.ActivateOrders,

	//[SysActions.ADDNOTES]: "mdi-clipboard-edit-outline",
	[SysActions.ADDNOTES]: FluentIconNames.EditNote,

	[SysActions.ADVANCE]: "mdi-forward",

	[SysActions.ALLOTFULL]: FluentIconNames.AlertSettings,

	[SysActions.APPLYTEMPLATE]: "APPLYTEMPLATE",

	//[SysActions.APPROVE]: "mdi-thumb-up-outline",
	//[SysActions.APPROVE]: "Accept",
	[SysActions.APPROVE]: FluentIconNames.Like,

	[SysActions.ARCHIVEDOWNLOAD]: "mdi-archive-arrow-down-outline",

	[SysActions.AUDITINTERNAL]: "cil-print",
	[SysActions.AUDITEXTERNAL]: "cil-external-link",

	[SysActions.AUTOALLOT]: "mdi-order-alphabetical-ascending",

	[SysActions.CANCELORDERS]: "mdi-text-box-remove-outline",

	//[SysActions.CERTRECEIVE]: "mdi-certificate-outline",
	[SysActions.CERTRECEIVE]: FluentIconNames.Certificate,

	//[SysActions.CHANGE]: "cil-swap-horizontal",
	[SysActions.CHANGE]: FluentIconNames.WindowEdit,

	[SysActions.CHANGEACCT]: "cil-swap-horizontal",

	//[SysActions.CHANGEASSIGNEE]: "CHANGEASSIGNEE",
	[SysActions.CHANGEASSIGNEE]: FluentIconNames.Assign,

	[SysActions.CHANGEFEE]: "cil-swap-horizontal",
	[SysActions.CHANGEFEESETSPLIT]: "cil-swap-horizontal",

	[SysActions.CHANGEOWNER]: "mdi-account-details",

	[SysActions.CHANGEPARENT]: "cil-sitemap",
	[SysActions.CHANGEPAY]: "mdi-cash-refund",
	[SysActions.CHANGEPOSITION]: "cil-swap-horizontal",

	[SysActions.CHANGERATE]: "cil-swap-horizontal",

	[SysActions.CHANGETEMPLATE]: " mdi-clipboard-flow", 
	[SysActions.CHANGEUSERNAMEEMAIL]: "mdi-account-edit-outline",

	[SysActions.CLEAR]: "mdi-eraser",

	[SysActions.CLEARALL]: "mdi-delete-sweep-outline",

	[SysActions.CLEARDOWNLOAD]: "mdi-download-off-outline",

	//[SysActions.CLOSE]: "cil-envelope-closed",
	//[SysActions.CLOSE]: "Close",
	[SysActions.CLOSE]: FluentIconNames.Folder,

	[SysActions.CONVERT]: "cil-cog",

	//[SysActions.COPY]: "mdi-content-copy",
	[SysActions.COPY]: FluentIconNames.Copy,

	[SysActions.COSTAUTOSETUP]: "cil-cog",
	[SysActions.COSTAUTOSETUPALL]: "cil-cog",

	//[SysActions.COSTREBUILD]: "cil-cog",
	[SysActions.COSTREBUILD]: FluentIconNames.Build,

	//[SysActions.COSTREBUILDALL]: "cil-cog",
	[SysActions.COSTREBUILDALL]: FluentIconNames.BuildQueue,

	//[SysActions.CREATE]: "mdi-plus",
	[SysActions.CREATE]: FluentIconNames.Add,

	[SysActions.CREATECASE]: "mdi-book-plus",

	[SysActions.CREATEMISSING]: "mdi-book-plus",

	//[SysActions.DEACTIVATE]: "mdi-bell-off-outline",
	[SysActions.DEACTIVATE]: FluentIconNames.DeactivateOrders,

	[SysActions.DEALALLOTAPPROVE]: "mdi-clipboard-check-outline",
	[SysActions.DEALALLOTAUTO]: "cil-list-numbered",
	[SysActions.DEALALLOTJOB]: "mdi-clipboard-list-outline",
	[SysActions.DEALALLOTRESEND]: "cil-list-high-priority",
	[SysActions.DEALALLOTSEND]: "cil-paper-plane",
	[SysActions.DEALALLOTUNAPPROVE]: "cil-x",
	[SysActions.DEALALLOTUNDO]: "cil-action-undo",

	//[SysActions.DELETE]: "cil-trash",
	[SysActions.DELETE]: FluentIconNames.Delete,

	[SysActions.DEMOBILIZE]: "cil-trash",

	[SysActions.DEPRECIATE]: "mdi-chart-ppf",
	[SysActions.DEPRECIATEALL]: "mdi-chart-sankey",

	[SysActions.DISBURSE]: "mdi-delete-empty-outline",

	//[SysActions.DISCARD]: "mdi-delete-forever-outline",
	[SysActions.DISCARD]: FluentIconNames.RecycleBin,

	[SysActions.DISCARDSTALE]: "mdi-delete-sweep-outline",

	[SysActions.DISPATCH]: "mdi-delete-empty-outline",

	[SysActions.DISPOSE]: "mdi-delete-empty-outline",

	[SysActions.DOCUMENTADD]: "mdi-book-plus-multiple-outline",
	[SysActions.DOCUMENTCHECK]: "mdi-chart-sankey",
	[SysActions.DOCUMENTVERIFY]: "mdi-download-circle-outline",
	[SysActions.DOCUMENTVOID]: "mdi-book-remove-multiple-outline",

	[SysActions.ERECEIVE]: "cil-share-boxed",

	//[SysActions.EXPORT]: "mdi-export",
	[SysActions.EXPORT]: FluentIconNames.Export,

	[SysActions.FAREPOST]: "icon-cloud-upload",

	[SysActions.FETCH]: "mdi-download-circle-outline",

	[SysActions.FILTER]: FluentIconNames.Filter,

	[SysActions.HANDOVER]: "cil-cog",

	[SysActions.HRCOMMUNICATE]: "cil-voice-over-record",

	//[SysActions.IMPORTVALID]: "mdi-import",
	[SysActions.IMPORTVALID]: FluentIconNames.FabricMovetoFolder,

	[SysActions.INITDO]: FluentIconNames.PlugConnected,
	[SysActions.INITUNDO]: FluentIconNames.PlugDisconnected,

	[SysActions.INTERESTRUN]: "mdi-chart-gantt",
	[SysActions.INTERESTRUNALL]: "mdi-chevron-triple-up",
	[SysActions.INTERESTREWIND]: "mdi-arrow-left-circle-outline",
	[SysActions.INTERESTREWINDALL]: "mdi-chevron-triple-down",

	[SysActions.INVALIDATE]: "mdi-file-remove-outline",

	[SysActions.KILL]: "mdi-close",

	[SysActions.LINK]: FluentIconNames.Link,

	[SysActions.LIST]: "cil-list",

	//[SysActions.LOADDATA]: "cil-reload",
	[SysActions.LOADDATA]: FluentIconNames.Import,

	[SysActions.LOADDEALS]: "mdi-file-document-outline",

	//[SysActions.LOCK]: "mdi-lock-outline",
	[SysActions.LOCK]: FluentIconNames.Lock,

	[SysActions.LOGCALL]: "mdi-account-cog-outline",

	[SysActions.LOGOUTFIX]: "mdi-account-cog-outline",

	[SysActions.MANUALDEALALLOT]: "mdi-clipboard-text-outline",

	[SysActions.MARKETDATA]: "mdi-chart-gantt",

	[SysActions.MERGE]: "mdi-call-merge",

	[SysActions.MIGRATECOMPANY]: "mdi-home-export-outline",

	[SysActions.MGTREVIEW]: "mdi-file-eye-outline",

	[SysActions.MOVESTORE]: "mdi-file-move-outline",

	[SysActions.MOVETODATE]: "mdi-file-move-outline",

	//[SysActions.OPEN]: "cil-envelope-open",
	[SysActions.OPEN]: FluentIconNames.FolderOpen,

	[SysActions.POST]: "mdi-table-lock",

	[SysActions.POSTRANGE]: "mdi-arrow-expand-vertical",

	//[SysActions.PRINT]: "cil-print",
	[SysActions.PRINT]: FluentIconNames.Print,

	[SysActions.PROCESS]: "cil-cog",
	[SysActions.PROCESSORDERS]: "mdi-file-cog-outline",

	[SysActions.PROPAGATE]: "mdi-content-copy",

	[SysActions.PURGE]: "mdi-delete-empty-outline",
	[SysActions.PURGEDEALS]: "mdi-delete-sweep-outliner",

	[SysActions.PWDCHANGE]: "mdi-account-key-outline",
	[SysActions.PWDCHANGEOWN]: "mdi-key",

	[SysActions.RECALL]: "mdi-redo",
	[SysActions.RECATEGORIZE]: "mdi-animation",

	[SysActions.RECEIVE]: "mdi-animation",

	[SysActions.RECOGNIZE]: "cil-search",
	[SysActions.RECOMPUTE]: "mdi-keyboard",
	[SysActions.RECOMPUTEALL]: "mdi-desktop-mac-dashboard",

	[SysActions.REFINANCE]: "mdi-refresh",

	//[SysActions.REFRESH]: "mdi-refresh",
	[SysActions.REFRESH]: FluentIconNames.Refresh,

	//[SysActions.REJECT]: "mdi-thumb-down-outline",
	[SysActions.REJECT]: FluentIconNames.Dislike,

	[SysActions.REPOST]: "mdi-mailbox-up-outline",

	[SysActions.RESEND]: "mdi-debug-step-over",

	[SysActions.RESPOND]: "mdi-mailbox-up-outline",

	[SysActions.RETRIEVE]: "mdi-replay",
	[SysActions.RETURNOWNER]: "mdi-account-convert-outline",

	[SysActions.REVERSE]: "mdi-restore",
	[SysActions.REVERSERANGE]: "cil-list-low-priority",
	[SysActions.REVERSESTATUS]: "cil-list-low-priority",

	[SysActions.REWIND]: "cil-media-skip-backward",

	[SysActions.ROLLOVER]: "cil-list-high-priority",

	//[SysActions.RUN]: "mdi-autorenew",
	//[SysActions.RUN]: "TriggerUser",
	[SysActions.RUN]: FluentIconNames.Play,

	[SysActions.RUNALL]: "mdi-atom-variant",

	//[SysActions.RUNSCHEDULE]: "mdi-calendar-sync-outline",
	[SysActions.RUNSCHEDULE]: FluentIconNames.TriggerAuto,

	//[SysActions.SAVE]: "cil-save",
	[SysActions.SAVE]: FluentIconNames.Save,
	[SysActions.SAVEALL]: FluentIconNames.SaveAll,
	[SysActions.SAVEANDCLOSE]: FluentIconNames.SaveAndClose,
	[SysActions.SAVEANDNEW]: FluentIconNames.SaveTemplate,

	[SysActions.SCORE]: "mdi-clipboard-edit-outline",

	//[SysActions.SEARCH]: "cil-search",
	[SysActions.SEARCH]: FluentIconNames.Search,

	[SysActions.SECURITYSTATUS]: "mdi-chart-gantt",

	//[SysActions.SEND]: "cil-send",
	//[SysActions.SEND]: "MailForward",
	[SysActions.SEND]: FluentIconNames.Send,

	[SysActions.SETFEESPLIT]: "mdi-call-split",
	[SysActions.SETTLE]: "cil-wc",

	[SysActions.SETUPFEED]: "mdi-rss",

	[SysActions.SKIP]: "mdi-debug-step-over",

	//[SysActions.SPLIT]: "mdi-call-split",
	[SysActions.SPLIT]: FluentIconNames.BranchFork,

	[SysActions.STARTFIX]: "mdi-tools",

	[SysActions.STARTTRADE]: "mdi-tools",

	[SysActions.STATUS]: "cil-signal-cellular3",
	[SysActions.STATUSORDERS]: "mdi-script-text-outline",

	[SysActions.STOPFIX]: "mdi-fruit-citrus-off", 

	[SysActions.SUBMIT]: "mdi-file-upload-outline", 
	[SysActions.SUSPEND]: "mdi-play-pause",

	[SysActions.TERMINATE]: "mdi-close",

	[SysActions.TRADECAPTURE]: "mdi-chart-line",

	[SysActions.UNAPPROVE]: "cil-x",
	[SysActions.UNDEPRECIATE]: "cil-sort-alpha-up",
	[SysActions.UNDEPRECIATEALL]: "cil-sort-ascending",

	//[SysActions.UNDISCARD]: "cil-action-undo",
	[SysActions.UNDISCARD]: FluentIconNames.EmptyRecycleBin,

	//[SysActions.UNFINALIZE]: "mdi-sort-variant-remove",
	[SysActions.UNFINALIZE]: FluentIconNames.RemoveFromTrash,

	//[SysActions.UNLOCK]: "mdi-lock-open-variant-outline",
	[SysActions.UNLOCK]: FluentIconNames.Unlock,

	[SysActions.UNPROCESS]: "cil-action-undo",
	[SysActions.UNSETTLE]: "mdi-scale-balance",

	//[SysActions.UPDATE]: "mdi-file-document-edit-outline",
	[SysActions.UPDATE]: FluentIconNames.Edit,

	[SysActions.VALIDATEALL]: "mdi-book-check-outline",

	[SysActions.VALIDATEDOWNLOAD]: "mdi-check-underline-circle-outline",

	[SysActions.VALUATIONDO]: "cil-arrow-top",
	[SysActions.VALUATIONDOALL]: "cil-sort-ascending",
	[SysActions.VALUATIONUNDO]: "cil-action-undo",
	[SysActions.VALUATIONUNDOALL]: "cil-sort-descending",

	[SysActions.VAULTINOPS]: "mdi-safe-square-outline",
	[SysActions.VAULTINSALES]: "mdi-safe",

	[SysActions.VERIFY]: "mdi-book-check-outline",

	//[SysActions.VIEW]: "mdi-eye",
	[SysActions.VIEW]: FluentIconNames.View,

	[SysActions.VIEWHISTORY]: "mdi-table-account",

	//[SysActions.VIEWLEDGER]: "mdi-table-account",
	[SysActions.VIEWLEDGER]: FluentIconNames.EntryView,

	[SysActions.VIEWVALUATION]: "mdi-table-account",
};
