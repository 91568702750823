import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, oneYearAgo, threeYearsAgo, today } from 'app-model/ModelConstants';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	EQCorpActionPryIssueList: {
        ...fluxListDefaults,
		actionNamespace: 'EQCorpActionPryIssueList',
		entityName: 'EQCorpActionPryIssue',
		fetchUriTemplate: 'EQCorpActionPryIssue',
		exportUriTemplate: 'EQCorpActionPryIssue/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQCorpActionPryIssuePick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQCorpActionPryIssuePick',
		entityName: 'EQCorpActionPryIssue',
		fetchUriTemplate: 'EQCorpActionPryIssue',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQCorpActionPryIssueAllotReturnList: {
		...fluxListDefaults,
		actionNamespace: 'EQCorpActionPryIssueAllotReturnList',
		entityName: 'EQCorpActionPryIssueAllotReturn',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn',
		exportUriTemplate: 'EQCorpActionPryIssueAllotReturn/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},

	EQCorpActionPryIssueAllotReturnDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQCorpActionPryIssueAllotReturnDocumentSubList',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Documents',
		exportUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Documents(${entityId})/Export',
	},

	EQCorpActionPryIssueRemitList: {
		...fluxListDefaults,
		actionNamespace: 'EQCorpActionPryIssueRemitList',
		entityName: 'EQCorpActionPryIssueRemit',
		fetchUriTemplate: 'EQCorpActionPryIssueRemit',
		exportUriTemplate: 'EQCorpActionPryIssueRemit/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},

	EQCorpActionPryIssueRemitItemSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQCorpActionPryIssueRemitItemSubList',
		entityName: 'EQCorpActionPryIssueRemitItem',
		fetchUriTemplate: 'EQCorpActionPryIssueRemit(${subListParentId})/RemitItems',
		exportUriTemplate: 'EQCorpActionPryIssueRemit(${subListParentId})/RemitItems/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQCorpActionPryIssueRemitDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQCorpActionPryIssueRemitDocumentSubList',
		fetchUriTemplate: 'EQCorpActionPryIssueRemit(${subListParentId})/Documents',
		exportUriTemplate: 'EQCorpActionPryIssueRemit(${subListParentId})/Documents(${entityId})/Export',
	},

	EQHoldingPryIssueList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueList',
		entityName: 'EQHoldingPryIssue',
		fetchUriTemplate: 'EQHoldingPryIssue',
		exportUriTemplate: 'EQHoldingPryIssue/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQHoldingPryIssuePick: {
		...fluxPickDefaults,
		actionNamespace: 'EQHoldingPryIssuePick',
		entityName: 'EQHoldingPryIssue',
		fetchUriTemplate: 'EQHoldingPryIssue',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: ''
		},
	},
	EQHoldingPryIssueSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueSubList',
		entityName: 'EQHoldingPryIssue',
		fetchUriTemplate: 'EQCorpActionPryIssue(${subListParentId})/Holdings',
		exportUriTemplate: 'EQCorpActionPryIssue(${subListParentId})/Holdings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQHoldingPryIssueDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQHoldingPryIssueDocumentSubList',
		fetchUriTemplate: 'EQHoldingPryIssue(${subListParentId})/Documents',
		exportUriTemplate: 'EQHoldingPryIssue(${subListParentId})/Documents(${entityId})/Export',
	},

	EQHoldingPryIssueAllotList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueAllotList',
		entityName: 'EQHoldingPryIssueAllot',
		fetchUriTemplate: 'EQHoldingPryIssueAllot',
		exportUriTemplate: 'EQHoldingPryIssueAllot/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQHoldingPryIssueAllotSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueAllotSubList',
		entityName: 'EQHoldingPryIssueAllot',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Allots',
		exportUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Allots/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQHoldingPryIssueReturnList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueReturnList',
		entityName: 'EQHoldingPryIssueReturn',
		fetchUriTemplate: 'EQHoldingPryIssueReturn',
		exportUriTemplate: 'EQHoldingPryIssueReturn/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQHoldingPryIssueReturnSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueReturnSubList',
		entityName: 'EQHoldingPryIssueReturn',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Returns',
		exportUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Returns/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}


const stViews : IViewComponentControlList = {
	EQCorpActionPryIssueView: {
		...fluxViewDefaults,
		actionNamespace: 'EQCorpActionPryIssueView',
		entityName: 'EQCorpActionPryIssue',
		fetchUriTemplate: 'EQCorpActionPryIssue',
		queryUseRestEndpoint: true,
	},
	
	EQCorpActionPryIssueAllotReturnView: {
		...fluxViewDefaults,
		actionNamespace: 'EQCorpActionPryIssueAllotReturnView',
		entityName: 'EQCorpActionPryIssueAllotReturn',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn',
		queryUseRestEndpoint: true,
	},

	EQCorpActionPryIssueRemitView: {
		...fluxViewDefaults,
		actionNamespace: 'EQCorpActionPryIssueRemitView',
		entityName: 'EQCorpActionPryIssueRemit',
		fetchUriTemplate: 'EQCorpActionPryIssueRemit',
		queryUseRestEndpoint: true,
	},

	EQHoldingPryIssueView: {
		...fluxViewDefaults,
		actionNamespace: 'EQHoldingPryIssueView',
		entityName: 'EQHoldingPryIssue',
		fetchUriTemplate: 'EQHoldingPryIssue',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
