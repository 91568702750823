import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { HRClosure } from 'app-model/enums';
import { PartnerGlAcctTypes, TermInstrumentClosure, } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, today, threeMonthsAgo, oneYearAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {

	HRPayrollReimburseList: {
		...fluxListDefaults,
		actionNamespace: 'HRPayrollReimburseList',
		entityName: 'HRPayrollReimburse',
		fetchUriTemplate: 'hrrequestsyear(${requestsYearId})/GetHRPayrollReimbursesForYear()',
		orderBy: 'RequestDate desc',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRPayItem($select=TypeCode,TypeName)',
		],
		searchFields: ['HRStaff/StaffNo', 'HRStaff/StaffName', 'Description'],
		filterValues: {
			searchText: '',
			requestsYearId: NullEntityId,
			payItemId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},

	HRPayrollReimburseDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRPayrollReimburseDocumentSubList',
		fetchUriTemplate: 'HRPayrollReimburse(${subListParentId})/Documents',
		exportUriTemplate: 'HRPayrollReimburse(${subListParentId})/Documents(${entityId})/Export',
	},

	HRPayrollReimburseWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRPayrollReimburseWorkflowHistorySubList',
		fetchUriTemplate: 'HRPayrollReimburse(${subListParentId})/WorkflowHistory',
		exportUriTemplate: 'HRPayrollReimburse(${subListParentId})/WorkflowHistory/Export',
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRPayrollReimburseView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPayrollReimburseView',
		entityName: 'HRPayrollReimburse',
		fetchUriTemplate: 'HRPayrollReimburse',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRPayItem($select=ElementClass,TypeCode,TypeName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
